
import { Typography } from '@material-ui/core';
import { Pagination, Skeleton } from '@material-ui/lab';
import Producto from '../Producto'
import './ProductosCatalogo.css'



export default function ProductosCatalogo(props) {

    const ClaseProducto = "px-1 px-sm-2 col-6 col-sm-4 col-md-6 col-lg-4 my-2"

    return (
        <div className={props.className}>                
                        
            <div className="row mx-md-0 justify-content-end align-items-center py-0 my-0">
                <div className="p-2 d-flex flex-row" style={{ minWidth: "140px" }}>
                    <Typography variant="caption">Ordenar por</Typography>
                    <select
                        onChange={(e) => {
                            props.DatosCargaPadre.setDatosCarga({ ...props.DatosCargaPadre.DatosCarga, orden: e.target.value,pagina:1 });
                            props.EstadoPadre.setEstado("inicio");
                            
                        }}
                        value={props.DatosCargaPadre.DatosCarga.orden}
                        className="form-control px-1">
                        <option value="sin_orden">Sin orden</option>
                        <option value="precio_asc">Precio Menor a Mayor</option>
                        <option value="precio_desc">Precio Mayor a Menor</option>
                        <option value="nombre_asc">Nombre Asc</option>
                        <option value="nombre_desc">Nombre Desc</option>

                    </select>

                </div>
            </div>

            {props.EstadoPadre.Estado === "cargado" && props.Productos ?
                <div>

                    <div className="row">
                        {
                            props.Productos.map((producto, index) => {
                                if (producto) {
                                    return (
                                        <Producto key={index} DatosProducto={producto} className={ClaseProducto}></Producto>
                                    );
                                } else {
                                    return (<div key={index}></div>);
                                }
                            })
                        }

                    </div>
                </div>
                :
                <div>
                    <div className="row">
                        <div className={ClaseProducto + " px-2"}>
                            <Skeleton height={405} variant="rect" />
                        </div>
                        <div className={ClaseProducto + " px-2"}>
                            <Skeleton height={405} variant="rect" />
                        </div>
                        <div className={ClaseProducto + " px-2"}>
                            <Skeleton height={405} variant="rect" />
                        </div>
                        <div className={ClaseProducto + " px-2"}>
                            <Skeleton height={405} variant="rect" />
                        </div>
                        <div className={ClaseProducto + " px-2"}>
                            <Skeleton height={405} variant="rect" />
                        </div>
                        <div className={ClaseProducto + " px-2"}>
                            <Skeleton height={405} variant="rect" />
                        </div>
                        <div className={ClaseProducto + " px-2"}>
                            <Skeleton height={405} variant="rect" />
                        </div>
                        <div className={ClaseProducto + " px-2"}>
                            <Skeleton height={405} variant="rect" />
                        </div>
                        <div className={ClaseProducto + " px-2"}>
                            <Skeleton height={405} variant="rect" />
                        </div>

                    </div>
                </div>
            }
            <div className="row pb-3 pt-2 justify-content-center">
                <Pagination
                    onChange={(e, pag_dest) => {
                        props.DatosCargaPadre.setDatosCarga({ ...props.DatosCargaPadre.DatosCarga, pagina: pag_dest });
                        props.EstadoPadre.setEstado("inicio");
                    }}
                    count={props.DatosCargaPadre.DatosCarga.cant_paginas} color="primary"></Pagination>
            </div>

        </div>
    );
}
