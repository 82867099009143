import { Skeleton } from "@material-ui/lab";

export default function CarruselImagenes(props) {

    const DatosProducto = props.DatosProducto;

    
    const EstiloImagenes = {
        maxHeight:"400px"
    }
    
    if(props.EstadoPadre==="cargado"){
        return (
            <div className={props.className}>
                <div>
                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">

                        <div className="carousel-inner overflow-inherit">
                            <div className="carousel-item active" style={EstiloImagenes}>
                                <img className="d-block w-100" src={process.env.PUBLIC_URL+"/assets/imagenes/productos/" + DatosProducto.img_01} alt="Img 1" style={{top:"0px"}} />
                            </div>
                            {DatosProducto.img_02?
                                <div className="carousel-item" style={EstiloImagenes}>
                                    <img className="d-block img-fluid" src={process.env.PUBLIC_URL+"/assets/imagenes/productos/" + DatosProducto.img_02} alt="Img 2" />
                                </div>
                            :""}
                            {DatosProducto.img_03?
                                <div className="carousel-item" style={EstiloImagenes}>
                                    <img className="d-block img-fluid" src={process.env.PUBLIC_URL+"/assets/imagenes/productos/" + DatosProducto.img_03}  alt="Img 3" />
                                </div>
                            :""}
                            {DatosProducto.img_04?
                                <div className="carousel-item" style={EstiloImagenes}>
                                    <img className="d-block img-fluid" src={process.env.PUBLIC_URL+"/assets/imagenes/productos/" + DatosProducto.img_04} alt="Img 3" />
                                </div>
                            :""}
                        </div>
                        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>

                </div>
                <ol className="carousel-indicators position-relative mt-1">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active border" style={{ width: "40px", height: "40px" }}>
                        <img className="d-block w-100" src={process.env.PUBLIC_URL+"/assets/imagenes/productos/" + DatosProducto.img_01} alt="Img 1" />
                    </li>
                    {DatosProducto.img_02?
                        <li data-target="#carouselExampleIndicators" data-slide-to="1" className="border" style={{ width: "40px", height: "40px" }}>
                            <img className="d-block w-100" src={process.env.PUBLIC_URL+"/assets/imagenes/productos/" + DatosProducto.img_02} alt="Img 2" />
                        </li>
                    :""}
                    {DatosProducto.img_03?
                        <li data-target="#carouselExampleIndicators" data-slide-to="2" className="border" style={{ width: "40px", height: "40px" }}>
                            <img className="d-block w-100" src={process.env.PUBLIC_URL+"/assets/imagenes/productos/" + DatosProducto.img_03} alt="Img 3" />
                        </li>
                    :""}
                    {DatosProducto.img_04?
                        <li data-target="#carouselExampleIndicators" data-slide-to="2" className="border" style={{ width: "40px", height: "40px" }}>
                            <img className="d-block w-100" src={process.env.PUBLIC_URL+"/assets/imagenes/productos/" + DatosProducto.img_04} alt="Img 3" />
                        </li>
                    :""}
                </ol>
            </div>

        );
    }else{
        return(
            <div className={props.className}>
                <Skeleton height={540} variant="rect"></Skeleton>
            </div>
        );
    }
}