import React, { useEffect } from "react";
import {
    Button,
    Card,
    CircularProgress,
    TextField,
    Typography,
} from "@material-ui/core";
import { useState } from "react";
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import axios from "axios";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InboxIcon from "@material-ui/icons/Inbox";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import ReCAPTCHA from "react-google-recaptcha";
//META
import MetaData from '../../context/MetaData.jsx'

const recaptchaRef = React.createRef();

export default function ContenidoTracking() {
    const [TrackingID, setTrackingID] = useState("");
    const [ErrorCampo, setErrorCampo] = useState({
        error: false,
        texto: "",
    });
    const [ErrorCampoCaptcha, setErrorCampoCaptcha] = useState({
        error: false,
        texto: "",
    });
    const [EstadoTracking, setEstadoTracking] = useState("inicio");
    const [DatosVenta, setDatosVenta] = useState({});

    const [GCaptchaConfig, setGCaptchaConfig] = useState({
        cargado: false,
        gsite_key: "",
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorCampo({error:false,texto:""});
        setErrorCampoCaptcha({error:false,texto:""});
        if (EstadoTracking === "cargando") {
            return false;
        }
        if (TrackingID.length <= 0) {
            setErrorCampo({
                error: true,
                texto: "Campo requerido",
            });
            return false;
        }
        if (recaptchaRef.current.getValue().length <= 0) {
            setErrorCampoCaptcha({
                error: true,
                texto: "Debe completar el Captcha",
            });
        }
        //Capa GCaptcha
        var datos = new FormData();
        datos.append("g-recaptcha-response", recaptchaRef.current.getValue());
        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/googleCaptcha/validar_captcha.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            //console.log(res);
            if (res[0] === "realizado") {
                setEstadoTracking("cargando");
                var datos = new FormData();
                datos.append("tracking", TrackingID);
                axios({
                    url:
                        process.env.PUBLIC_URL +
                        "/assets/api/tracking_cargar.php",
                    method: "post",
                    data: datos,
                }).then((resp) => {
                    var res = resp.data;
                    if (res[0] === "realizado") {
                        setEstadoTracking("cargado");
                        setDatosVenta(res["datos_venta"]);
                    } else if (res[0] === "error") {
                        setEstadoTracking("error");
                    } else {
                    }
                });
            } else if (res[0] === "error_rechazado") {
                recaptchaRef.current.reset();
                setErrorCampoCaptcha({
                    error: true,
                    texto: "Captcha inválido, reingrese.",
                });
            }
        });
    };

    useEffect(() => {
        const cargarCaptcha = () => {
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/googleCaptcha/cargar_captcha.php",
            }).then((resp) => {
                var res = resp.data;
                if (res[0] === "realizado") {
                    setGCaptchaConfig({
                        cargado: true,
                        gsite_key: res["gsite_key"],
                    });
                }
            });
        };

        cargarCaptcha();
    }, []);

    return (
        <div>
            <MetaData titulo="Tracking"/>
            <Ruta
                ruta={[{ nombre: "Inicio", ruta: "/" }, { nombre: "Tracking" }]}
            ></Ruta>

            <div className="bg-light">
                <div className="container">
                    <Titulo className="pb-3" titulo="Tracking"></Titulo>
                    <div className="row pb-4">
                        <div className="col">
                            <Card
                                style={{ minHeight: window.innerHeight * 0.7 }}
                            >
                                <div className="row mx-0 justify-content-center my-3">
                                    <Typography variant="h5">
                                        Ingrese el ID de Tracking
                                    </Typography>
                                </div>
                                <div className="row mx-0 justify-content-center">
                                    <form
                                        onSubmit={(e) => {
                                            handleSubmit(e);
                                        }}
                                        className="d-flex flex-column"
                                    >
                                        <TextField
                                            onChange={(e) => {
                                                setTrackingID(e.target.value);
                                                setErrorCampo({
                                                    error: false,
                                                    texto: "",
                                                });
                                            }}
                                            variant="outlined"
                                            label="Tracking ID"
                                            error={ErrorCampo.error}
                                            helperText={ErrorCampo.texto}
                                        ></TextField>
                                        {GCaptchaConfig.cargado ? (
                                            <div className="my-1">
                                                <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    sitekey={
                                                        GCaptchaConfig.gsite_key
                                                    }
                                                ></ReCAPTCHA>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {
                                            ErrorCampoCaptcha.error?
                                            <div className="row justify-content-center">
                                                <Typography variant="body2" className="text-danger font-weight-bold mb-1">
                                                    * {ErrorCampoCaptcha.texto} *
                                                </Typography>
                                            </div>:""
                                        }
                                        <Button
                                            type="submit"
                                            className="text-white mt-1"
                                            style={{
                                                backgroundColor: "#1a1919",
                                            }}
                                        >
                                            Buscar
                                        </Button>
                                    </form>
                                </div>
                                {EstadoTracking === "cargado" ? (
                                    <EstadoVenta
                                        DatosVenta={DatosVenta}
                                    ></EstadoVenta>
                                ) : (
                                    ""
                                )}
                                {EstadoTracking === "error" ? (
                                    <div className="row mt-4 justify-content-center">
                                        <Typography
                                            className="font-weight-bold text-danger"
                                            variant="body2"
                                        >
                                            * Venta no encontrada *
                                        </Typography>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {EstadoTracking === "cargando" ? (
                                    <div className="row justify-content-center my-3">
                                        <CircularProgress
                                            style={{ color: "#ff6400" }}
                                        ></CircularProgress>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function EstadoVenta(props) {
    let { DatosVenta } = props;

    return (
        <div className="row mx-0 justify-content-center mt-4">
            <div className="d-flex flex-column">
                <div>
                    <Typography
                        className="text-center font-weight-bold"
                        style={{ color: "#1a1919" }}
                    >
                        Estado Venta{" "}
                        <span style={{ color: "#ff6400" }}>
                            N# {DatosVenta.ID}
                        </span>
                    </Typography>
                </div>
                <div className="d-flex flex-row align-items-center my-2">
                    <CheckCircleIcon
                        className="text-success"
                        fontSize="large"
                    ></CheckCircleIcon>
                    <Typography
                        variant="h6"
                        className="ml-2 text-success font-weight-bold"
                    >
                        Ingresada y en Preparacion
                    </Typography>
                </div>
                {DatosVenta.listo > 0 ? (
                    <div className="d-flex flex-row align-items-center my-2">
                        <InboxIcon
                            className="text-success"
                            fontSize="large"
                        ></InboxIcon>
                        <Typography
                            variant="h6"
                            className="ml-2 text-success font-weight-bold"
                        >
                            Preparada
                        </Typography>
                    </div>
                ) : (
                    <div className="d-flex flex-row align-items-center my-2">
                        <InboxIcon
                            className="text-secondary"
                            fontSize="large"
                        ></InboxIcon>
                        <Typography
                            variant="h6"
                            className="ml-2 text-secondary font-weight-bold"
                        >
                            Preparada
                        </Typography>
                    </div>
                )}
                {DatosVenta.tipo_despacho === "Despacho" ? (
                    <>
                        {DatosVenta.entregado > 0 ? (
                            <div className="d-flex flex-row align-items-center my-2">
                                <LocalShippingIcon
                                    className="text-success"
                                    fontSize="large"
                                ></LocalShippingIcon>
                                <Typography
                                    variant="h6"
                                    className="ml-2 text-success font-weight-bold"
                                >
                                    Despachada
                                </Typography>
                            </div>
                        ) : (
                            <div className="d-flex flex-row align-items-center my-2">
                                <LocalShippingIcon
                                    className="text-secondary"
                                    fontSize="large"
                                ></LocalShippingIcon>
                                <Typography
                                    variant="h6"
                                    className="ml-2 text-secondary font-weight-bold"
                                >
                                    Despachada
                                </Typography>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        {DatosVenta.entregado > 0 ? (
                            <div className="d-flex flex-row align-items-center my-2">
                                <MarkunreadMailboxIcon
                                    className="text-success"
                                    fontSize="large"
                                ></MarkunreadMailboxIcon>
                                <Typography
                                    variant="h6"
                                    className="ml-2 text-success font-weight-bold"
                                >
                                    Retirada
                                </Typography>
                            </div>
                        ) : (
                            <div className="d-flex flex-row align-items-center my-2">
                                <MarkunreadMailboxIcon
                                    className="text-secondary"
                                    fontSize="large"
                                ></MarkunreadMailboxIcon>
                                <Typography
                                    variant="h6"
                                    className="ml-2 text-secondary font-weight-bold"
                                >
                                    Retirada
                                </Typography>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
