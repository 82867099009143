import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import Ruta from '../components/Ruta'
import Titulo from '../components/Titulo'
import { Typography } from '@material-ui/core'
//META
import MetaData from '../context/MetaData'


export default function Acercaden(){

    const Rutas = [
        {nombre:"Inicio",ruta:"/"},
        {nombre:"Acerca de Nosotros"}
    ]

    return(
        <div>
            <MetaData titulo="Acerca de Nosotros">
            </MetaData>
            <Header></Header>
            <Ruta ruta={Rutas}></Ruta>            
            <div className="container" style={{minHeight:window.innerHeight*0.6}}>
                
                <Titulo className="py-3" titulo="Acerca de Nosotros"></Titulo>
                
                <div className="row pt-3 pb-5">
                    <div className="col-md-7">
                        <Typography className="text-justify">
                            Somos el primer Patio Constructor fundado en la Región de Valparaíso. En 1996 se crea la sociedad Anónima Cerrada Dimasa S.A., originalmente como productora y comercializadora de Madera, tempranamente se amplió a Materiales de Construcción y Ferretería en general.
<br/><br/>
Hoy, somos uno de los actores destacados de la región y contamos con 10.000 m2 para atender a nuestros clientes junto a una potente plataforma digital y de redes sociales para brindar una experiencia de compra de la más alta calidad.
<br/><br/>
En Dimasa estamos enfocados en nuestros Clientes. La atención personalizada, la preocupación por sus necesidades y el trato amable son nuestro sello. Más allá de ofrecer un servicio flexible creemos que la personalización es fundamental para atender las necesidades de nuestros clientes, los escuchamos y entendemos. 
<br/><br/>
Por eso contamos con un amplio abanico de plataformas digitales como Instagram, Facebook, Linkedin y nuestro Whatsapp Business, que a través de nuestros asesores expertos, que son personas altamente capacitadas y no chatbots, entregamos a nuestros clientes una atención personalizada y focalizada en sus requerimientos e intereses, con la finalidad de ofrecerles soluciones inmediatas y entregarles un asesoramiento de acuerdo a las necesidades específicas de cada uno de ellos. 
<br/><br/>
Nuestro equipo de profesionales trabaja día a día para mejorar la experiencia de compra de nuestros clientes y es por esto que desarrollamos un nuevo sitio web con todas las funcionalidades necesarias y una navegación simplificada, haciéndolo mucho más amigable y fácil de explorar.
<br/><br/>
Estamos conscientes que nuestros clientes son los que le dan vida y sustento a nuestra empresa, que nos debemos a ellos y que perdurar en el tiempo depende de que vengan a visitarnos y que se vayan contentos y satisfechos. Pero no solo lo hacemos por convencimiento, lo hacemos porque es nuestra forma y porque es un reflejo de lo que somos.  
<br/><br/>
En Dimasa, estamos cultivando y potenciando la forma en la que atendemos a nuestros Clientes. Esta forma es propia, nuestra y la hemos construido en el tiempo, y hoy, la estamos fortaleciendo asesorándonos y preparándonos para recibir tanto a nuestros clientes tradicionales como a los nuevos que llegarán.
<br/><br/>
Estamos transformando nuestra organización, mejorando la infraestructura, adaptándonos a nuevas tecnologías, construyendo una manera especial y propia para recibir y atender a nuestros clientes.  Pero, también estamos valorando y potenciando nuestra cultura. 
<br/><br/>
En Dimasa, somos una gran familia. La atención personalizada con la que recibimos y acogemos a nuestros Clientes es sincera y transparente, lo que en gran medida es reflejo de una cultura organizacional sana y de un buen ambiente de trabajo, los que procuramos mantener y cuidar. Nuestro cliente tiene nombre, apellido, necesidades particulares y lo atienden personas únicas y especiales, personas que desarrollamos contentos nuestro trabajo, que lo pasamos bien realizándolo y que nos sentimos parte de nuestro Dimasa.
                        </Typography>
                        
                    </div>
                    <div className="col-md-5">
                        <div className="">
                            <img src={process.env.PUBLIC_URL+"/assets/imagenes/acercaden.jpg"} alt="sup" className="img-fluid rounded shadow-sm"/>
                        </div>
                        <div className="mt-3">
                            <img src={process.env.PUBLIC_URL+"/assets/imagenes/acercaden2.jpg"} alt="sup" className="img-fluid rounded shadow-sm"/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}
