
import { useQuill } from 'react-quilljs';
// or const { useQuill } = require('react-quilljs');

import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { useEffect } from 'react';


export default function QuillBox(props){

    let {contenido} = props

    const theme = 'snow';
    // const theme = 'bubble';

    const modules = {
        toolbar: false        
    };
    

    const { quill, quillRef } = useQuill({ theme, modules});       

    useEffect(() => {
        var Estado = "inicio";

        const cargarContenido=()=>{
            Estado="cargado";
            quill.setContents(contenido)
        }

        if(Estado==="inicio"){
            if(quill){
                cargarContenido();
                quill.disable();
            }
        }   
        
        
        
    }, [quill,contenido]);


    return(
        <div className={props.className}>
            <div className="border-0 p-0" ref={quillRef} />
        </div>
    );
}
