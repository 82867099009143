import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoCarroVerEt2 from '../components/carro/ContenidoCarroVerEt2.jsx'

export default function Carro(){
    return(
        <div>
            <Header></Header>
            <ContenidoCarroVerEt2></ContenidoCarroVerEt2>
            <Footer></Footer>

        </div>
    );
}