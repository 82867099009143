import { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import EstilosGlobales from "../../../context/EstilosGlobales";
import axios from "axios";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { animated, useSpring } from "react-spring";
import useWindowSize from '../../../context/useWindowSize';
import ListIcon from '@material-ui/icons/List';

export default function MenuBlog(props) {
    const [CategoriasBlogPrime, setCategoriasBlogPrime] = useState([]);
    const [CategoriasBlogSec, setCategoriasBlogSec] = useState([]);
    const [DropDown, setDropDown] = useState(false);
    const [CategoriasAll,setCategoriasAll] = useState([]);
    const size = useWindowSize();

    useEffect(() => {
        const cargarCategoriasBlog = () => {
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/blog/cargar_categorias.php",
            }).then((resp) => {
                var res = resp.data;
                //console.log(res);
                setCategoriasBlogPrime(res.slice(0, 5));
                if (res.length >= 6) {
                    setCategoriasBlogSec(res.slice(5, res.length));
                }
                setCategoriasAll(res);
            });
        };
        cargarCategoriasBlog();
    }, []);


    if(parseInt(size.width)>839){
        return (
            <div
                className={props.className + " d-flex flex-row align-items-center"}
            >
                {CategoriasBlogPrime.map((categoria, index) => {
                    return (
                        <Link
                            className="text-decoration-none"
                            to={"/blog/" + categoria.ID}
                            key={index}
                        >
                            <Typography
                                className="font-weight-bold mx-3"
                                variant="caption"
                                style={{ color: EstilosGlobales.colorSecundario }}
                            >
                                {categoria.nombre}
                            </Typography>
                        </Link>
                    );
                })}
                {CategoriasBlogSec.length > 0 ? (
                    <>
                        <div
                            className="d-flex flex-row align-items-center justify-content-center mt-1 position-relative"
                            onClick={(e)=>{
                                setDropDown(true) ;
                            }}
                        >
                            <Typography
                                className="font-weight-bold mx-3"
                                variant="caption"
                                style={{ color: EstilosGlobales.colorSecundario ,
                                    cursor:"pointer"
                                }}
                            >
                                Más
                            </Typography>
                            <ArrowDropDownIcon fontSize="small" />
                            <SubMenuBlogSec
                                setDropDown = {setDropDown}
                                CategoriasBlogSec={CategoriasBlogSec}
                                DropDown={DropDown}
                            />
                        </div>
                    </>
                ) : (
                    ""
                )}
            </div>
        );
    }else{
        return(
            <MenuBlogXS CategoriasAll={CategoriasAll} size={size}/>
        );
    }
}

function MenuBlogXS(props){
    let {CategoriasAll,size} = props;

    const [Dropdown,setDropdown] = useState(false);

    const EstilosMenu = useSpring({
        zIndex:"1000",
        top:"0px",
        left:"0px",
        height:size.height,
        width:size.width,
        display:Dropdown?"block":"none",
        config:{
            duration:200
        }
    });

    const EstilosBackground=useSpring({
        backgroundColor:"#1a1919",
        top:"0px",
        left:"0px",
        zIndex:"1001",
        opacity:Dropdown?"0.4":"0",
        height:size.height,
        width:size.width,
        config:{
            duration:200
        }
    });
    const EstilosMenuInside=useSpring({
        top:"0px",
        left:"0px",
        zIndex:"1002",
        height:size.height,
        opacity:Dropdown?"1":"0",
        width:"250px",
        transform:Dropdown?"translate(0px,0)":"translate(-100px,0)",
        config:{
            duration:200
        }
    });
    return(
        <div className="">
            <div 
                className="d-flex flex-row align-items-center"
                onClick={()=>{
                    setDropdown(!Dropdown);
                }}
                style={{cursor:"pointer"}}
            >
                <ListIcon variant="small"/>
                <Typography variant="body2" className="ml-1 font-weight-bold" >
                    Secciones 
                </Typography>
            </div>

            <animated.div className="MenuBlogDroped position-fixed" style={EstilosMenu}>
                <animated.div onClick={()=>{if(Dropdown)setDropdown(false);}} className="background" style={EstilosBackground}>
                </animated.div>
                <animated.div className="position-absolute MenuBlogXS bg-light shadow-sm px-3 py-1" style={EstilosMenuInside}>
                    <div className="d-flex flex-row justify-content-end">
                        <div className="font-weight-bold" style={{cursor:"pointer"}}
                            onClick={()=>{
                                setDropdown(false);
                            }}
                        >
                            <Typography className="font-weight-bold text-secondary" variant="h5">
                                X 
                            </Typography>
                        </div>
                    </div>
                    <div className="">
                        <Typography className="font-weight-bold">
                            Nuestras Secciones
                        </Typography>
                    </div>
                    <div className="mt-2">
                        {
                            CategoriasAll.map((categoria,index)=>{
                                return(
                                    <Link to={"/blog/"+categoria.ID} onClick={()=>{if(Dropdown)setDropdown(false);}} key={index}>
                                        <Typography variant="body2" className="my-1"> 
                                            {categoria.nombre}
                                        </Typography> 
                                    </Link>
                                )
                            })
                        }
                    </div>
                </animated.div>
            </animated.div>
        </div>
    );
}

function SubMenuBlogSec(props) {
    let { CategoriasBlogSec,setDropDown,DropDown} = props;
    const EstiloMenu = useSpring({
        zIndex: "100",
        top: "20px",
        minWidth: "150px",
        borderRadius: "12px 12px 12px 12px",
        opacity:DropDown?"1":"0",
        display:DropDown?"block":"none",
        config:{duration:200}
    })

    const EstiloFondo = useSpring({
        zIndex: "50",
        top: "0px",
        left: "0px",
        display:DropDown?"block":"none",
        opacity: DropDown?"0.3":"0",
        config:{duration:200}
    })
    return (
        <>
            <animated.div
                className="position-absolute bg-white px-2 py-3 shadow-sm border"
                onMouseLeave={()=>{
                    setDropDown(false);
                }} 
                style={EstiloMenu}
            >
                <div className="d-flex flex-column">
                    {CategoriasBlogSec.map((categoria, index) => {
                        return (
                            <Link
                                key={index}
                                className="text-decoration-none"
                                to={"/blog/" + categoria.ID}
                            >
                                <Typography
                                    className="font-weight-bold mx-3"
                                    variant="caption"
                                    style={{
                                        color: EstilosGlobales.colorSecundario,
                                    }}
                                >
                                    {categoria.nombre}
                                </Typography>
                            </Link>
                        );
                    })}
                </div>
            </animated.div>
            <animated.div
                className="position-fixed bg-dark w-100 h-100"
                style={EstiloFondo}
            ></animated.div>
        </>
    );
}
