import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoBlogSec from '../components/blog/ContenidoBlogSec'
import MetaData from '../context/MetaData'

export default function Blog(){
    return(
        <div>
            <MetaData titulo="Secciones"/>
            <Header></Header>
            <ContenidoBlogSec></ContenidoBlogSec>
            <Footer></Footer>
        </div>
    );
}

