import {useState,useEffect, useContext} from 'react'
import BannerInicio from './BannerInicio'
import ProductosInicio from './ProductosInicio'
import CategoriaProductos from '../inicio/CategoriaProductos'
import axios from 'axios'
//CYBER MODE
import CyberModeContext from '../../context/CyberModeContext'
//import {DragIndicatorSharp} from '@material-ui/icons'

export default function ContenidoInicio(){
    const {CyberMode,DatosCyberMode} = useContext(CyberModeContext);
    
    const [ProductosDestacados,setProductosDestacados] = useState();
    const [ProductosDestacadosCargado,setProductosDestacadosCargado] = useState(false);
    
    const [ProductosOferta,setProductosOferta] = useState();
    const [ProductosOfertaCargado,setProductosOfertaCargado] = useState(false);
    const [NombreOferta,setNombreOferta] = useState("");
    useEffect(()=>{
        const cargarProductosDestacados=()=>{    
            axios({
                url:process.env.PUBLIC_URL+"/assets/api/productos_dest_cargar.php"
            }).then((resp)=>{
                var res = resp.data;
                setProductosDestacados(res); 
                setProductosDestacadosCargado(true);
            })
        }
        const cargarProductosOferta=()=>{    
            axios({
                url:process.env.PUBLIC_URL+"/assets/api/productos_oferta_cargar.php"
            }).then((resp)=>{
                var res = resp.data;
                setProductosOferta(res); 
                setProductosOfertaCargado(true);
            })
        }
        cargarProductosDestacados();
        cargarProductosOferta();

    },[])

    useEffect(()=>{
        //SET NOMBRE OFERTA
        if(CyberMode){
            setNombreOferta(DatosCyberMode.cyber_nombre+" OFERTAS");
        }else{
            setNombreOferta("PRODUCTOS EN OFERTA");
        }
    },[DatosCyberMode.cyber_nombre])

    


    return(
        <div className="container">
            <BannerInicio></BannerInicio> 
            {
                ProductosOferta && ProductosOferta.length>0?
                <ProductosInicio cargado={ProductosOfertaCargado} titulo={NombreOferta} productos={ProductosOferta}></ProductosInicio>                       
                :""
            }
            {
                ProductosDestacados && ProductosDestacados.length>0?
                <ProductosInicio cargado={ProductosDestacadosCargado} titulo="PRODUCTOS DESTACADOS" productos={ProductosDestacados}></ProductosInicio>                       
                :""
            }
            <CategoriaProductos></CategoriaProductos>
        </div>
    );
}
