import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';
import { useEffect, useState } from 'react';

export default function Whatsapp(){

    const paddingY = "10px";
    const Estilos = {
        bottom:"20px",
        right:"20px",
        zIndex:"2000"       
    }

    const EstilosBoton = {
        paddingTop:paddingY,
        paddingBottom:paddingY
    }

    const [LinkWhatsapp,setLinkWhatsapp] = useState("#");
    const [Estado,setEstado] = useState("inicio");

    useEffect(()=>{
        if(Estado==="inicio"){
            cargarWhatsapp();
        }
    })

    const cargarWhatsapp=()=>{
        setEstado("cargando");
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/global/config.json"            
        }).then((resp)=>{
            var res = resp.data;
            setEstado("cargado");
            setLinkWhatsapp(res.Empresa.whatsapp);
        })
    }

    return(
        <div className="position-fixed" style={Estilos}>
            {
                Estado==="cargado"?
                <a target="_blank" rel="noreferrer" href={LinkWhatsapp}>
                    <button style={EstilosBoton} className="btn btn-success rounded-circle shadow">
                        <WhatsAppIcon fontSize="large" className="text-white"></WhatsAppIcon>
                    </button>
                </a>
                :""
            }
        </div>
    );
}
