import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoLogin from '../components/cuentas/ContenidoLogin.jsx'
import MetaData from '../context/MetaData'

export default function Login(){
    return(
        <div>         
            <MetaData titulo="Login"/>
            <Header>            
            </Header>
            <ContenidoLogin></ContenidoLogin>
            <Footer></Footer>
        </div>
    );
}
