import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoBlog from '../components/blog/ContenidoBlog'

export default function Blog(){
    return(
        <div>
            <Header></Header>
            <ContenidoBlog></ContenidoBlog>
            <Footer></Footer>
        </div>
    );
}

