import {useEffect,useState} from 'react'

export default function useWindowScroll() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [ScrollSize, setScrollSize] = useState({
      varY: 0,
      varX:0
    });
  
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setScrollSize({
          varY:window.scrollY,
          varX:window.scrollX 
        });
      }
      
      // Add event listener
      window.addEventListener("scroll", handleResize);
      
      // Call handler right away so state gets updated with initial window size
      handleResize();
      
      // Remove event listener on cleanup
      return () => window.removeEventListener("scroll", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
  
    return ScrollSize;
  }
