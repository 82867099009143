import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoSuperfamilias from '../components/superfamilias/ContenidoSuperfamilias.jsx'

export default function Superfamilias(){
    return(
        <>
            <Header></Header>
            <ContenidoSuperfamilias></ContenidoSuperfamilias>
            <Footer></Footer>
        </>
    );
}