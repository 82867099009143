import { useEffect, useState } from "react";
import { Card, Typography, CircularProgress, Button } from "@material-ui/core";
import axios from "axios";
import EstilosGlobales from "../../../context/EstilosGlobales";
import number_format from "../../../context/numberFormat";

export default function Compras() {
    const [Compras, setCompras] = useState([]);
    const [Cargado, setCargado] = useState(false);
    const [Link, setLink] = useState("");

    useEffect(() => {
        const cargarCompras = () => {
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/cuentas/micuenta_compras_cargar.php",
            }).then((resp) => {
                var res = resp.data;
                //console.log(res);
                if (res[0] === "realizado") {
                    setCompras(res["ventas"]);
                    setLink(res["DIR_PAGINA"]);
                    setCargado(true);
                }
            });
        };
        if (!Cargado) cargarCompras();
    }, [Cargado]);

    if (Cargado) {
        return (
            <Card className="" style={{ minHeight: "392px" }}>
                <div className="row mx-md-4 mx-1">
                    <Typography className="mt-2" variant="h6">
                        Últimas Compras
                    </Typography>
                </div>
                <hr />
                <div className="px-2 mx-1">
                    <table className="table table-sm rounded shadow-sm">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Tipo Desp.</th>
                                <th scope="col">Total</th>
                                <th scope="col">Estado</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Compras.map((compra, index) => {
                                var estado_compra = "Por preparar";
                                if (compra.listo > 0 && compra.entregado <= 0) {
                                    estado_compra = "Preparada";
                                } else if (
                                    compra.listo > 0 &&
                                    compra.entregado > 0
                                ) {
                                    if (compra.tipo_despacho === "Despacho") {
                                        estado_compra = "Despachada";
                                    } else if (
                                        compra.tipo_despacho === "Retiro"
                                    ) {
                                        estado_compra = "Retirada";
                                    }
                                }

                                return (
                                    <tr key={index}>
                                        <th
                                            scope="row"
                                            style={{
                                                color:
                                                    EstilosGlobales.colorPrimario,
                                            }}
                                        >
                                            {compra.ID}
                                        </th>
                                        <td>
                                            <Typography className="text-dark">
                                                {compra.fecha}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography className="font-weight-bold text-primary">
                                                {compra.tipo_despacho}
                                            </Typography>
                                        </td>

                                        <td>
                                            <Typography className="font-weight-bold">
                                                ${" "}
                                                {number_format(
                                                    compra.total_final,
                                                    0,
                                                    ",",
                                                    "."
                                                )}
                                            </Typography>
                                        </td>
                                        <td>
                                            <Typography className="text-success font-weight-bold">
                                                {estado_compra}
                                            </Typography>
                                        </td>
                                        <td>
                                            <a
                                                href={
                                                    Link +
                                                    "assclient/venta_print.php?id=" +
                                                    compra.ID +
                                                    "&token_ws=" +
                                                    compra.tokenWs
                                                }
                                                className="text-decoration-none"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        backgroundColor:
                                                            EstilosGlobales.colorPrimario,
                                                    }}
                                                    className="text-white"
                                                    size="small"
                                                >
                                                    Detalle
                                                </Button>
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Card>
        );
    } else {
        return (
            <div className="row justify-content-center">
                <div className="d-flex flex-row align-items-center">
                    <CircularProgress
                        style={{ color: EstilosGlobales.colorPrimario }}
                    />
                    <Typography className="ml-3">Cargando...</Typography>
                </div>
            </div>
        );
    }
}
