import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoRecuperarPass from '../components/cuentas/ContenidoRecuperarPass.jsx'
import MetaData from '../context/MetaData'

export default function Login(){
    return(
        <div>         
            <MetaData titulo="Recuperar Contraseña"/>
            <Header>            
            </Header>
            <ContenidoRecuperarPass></ContenidoRecuperarPass>
            <Footer></Footer>
        </div>
    );
}
