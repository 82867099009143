import {Typography} from '@material-ui/core'

export default function Titulo(props) {
    var sizeTexto = "";
    if(props.size && props.size==="small"){
        sizeTexto="h6";
    }else if(props.size && props.size==="xsmall"){
        sizeTexto="body1";
    }else{
        sizeTexto="h5"
    }
    return (
        <div className={props.className}>
            <div className="row">
                <Typography className="font-weight-bold" variant={sizeTexto}>{props.titulo}{props.children}</Typography>
            </div>
            <div className="row position-relative">
                <div className="border-bottom position-relative" style={{ width: "100%", height: "1px" }}>

                </div>
                <div className="position-absolute" style={{ width: "110px", height: "4px", left: "0px", backgroundColor: "#FF6400", bottom: "-1.5px" }}>

                </div>
            </div>
        </div>
    );
}
