import { useState, useEffect } from 'react'
import { Button, Card, CardActionArea, Typography } from '@material-ui/core'

import ListIcon from '@material-ui/icons/List';
import './MenuCategorias.css'
import axios from 'axios';
import { Link } from 'react-router-dom'

import { useSpring, animated } from 'react-spring'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';



export default function MenuCategorias() {

    const paddingY = "12px";
    const EstiloDropDown = {
        top: "45px",
        left: "0px",
        zIndex: "300"
    }
    /* const EstiloLinea = {
        height: "2px",
        width: "50%",
        backgroundColor: "#FF6400"
    } */
    const propsanim = useSpring({ opacity: 1, from: { opacity: 0 } });

    const [Estado, setEstado] = useState("inicio");
    const [Superfamilias, setSuperfamilias] = useState([{}]);
    const [DropDown,setDropDown] = useState(false);
    

    useEffect(() => {
        if (Estado === "inicio") {
            cargarSuperfamilias();
        }
    })

    const cargarSuperfamilias = () => {
        setEstado("cargando");
        axios({
            url: process.env.PUBLIC_URL + "/assets/api/superfamilias_cargar.php"
        }).then((resp) => {
            var res = resp.data;
            setSuperfamilias(res);
            setEstado("cargado");
            
        })
    }

    const togleDropDown=()=>{
        setDropDown(!DropDown);
    }


    return (
        <div className="position-relative" style={{ backgroundColor: "#ff6400" }}>
            <Button onClick={togleDropDown} className="font-weight-bold text-white px-4 position-relative" style={{ paddingTop: paddingY, paddingBottom: paddingY }}>
                Catálogo Productos
                <ListIcon fontSize="small" className="ml-2"></ListIcon>
            </Button>
            {
                DropDown?
                <ClickAwayListener onClickAway={togleDropDown}>
                    <div className="position-absolute bg-light rounded shadow" style={EstiloDropDown}>
                        <animated.div style={propsanim}>
                            <div className="row justify-content-start dropdownCategorias px-4 py-2">
                                {
                                    Superfamilias.map((superfamilia, index) => {
                                        /* var familias_print = []; */
                                        /* if (superfamilia.familias) {
                                            familias_print = superfamilia.familias;
                                        } */
                                        return (
                                            <div key={index} className="col-md-3 col-sm-4 my-2 col-6">
                                                <Link 
                                                onClick={()=>{
                                                    setDropDown(false);
                                                }}                                                
                                                className="text-decoration-none" to={"/superfamilias/"+superfamilia.superfamilia}>
                                                    <Card>
                                                    <CardActionArea className="py-2">
                                                    <div className="d-flex flex-column align-items-center">
                                                        <div className="cont_imagen_superfamilia_menu_categoria">
                                                            <img className="img-fluid" src={process.env.PUBLIC_URL+"/assets/imagenes/superfamilias/"+superfamilia.imagen} alt="sup"></img>
                                                        </div>
                                                        <Typography style={{color:"#ff6400"}} className="text-center font-weight-bold" variant="caption">
                                                            {superfamilia.superfamilia}    
                                                        </Typography>                                                    
                                                    </div>
                                                    </CardActionArea>
                                                    </Card>
                                                </Link>
                                            </div>
                                        );
                                    })
                                }
                                
                            </div>
                        </animated.div>
                    </div>
                </ClickAwayListener>
                :
                ""
            }
        </div>
    );
}
