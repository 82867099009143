import {useState,useEffect} from 'react'
import Carousel from '../Carousel'
import axios from 'axios'
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    colorPrimary: {
        background: "#ff9d5e"    
    },
    barColorPrimary:{
        background:"#ff6400"
    }
  });

export default function BannerInicio(){
    const [Cargado,setCargado] = useState(false);
    const [Banners,setBanners] = useState([
        
    ]);

    const classes = useStyles();
    

    useEffect(()=>{
        if(!Cargado){
            cargarBanners();
        }
    })

    const cargarBanners=()=>{
        /* setCargado(true);    */
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/banners_cargar.php"            
        }).then((resp)=>{
            var res = resp.data;            
            setCargado(true);
            setBanners(res);
        })
    }

    return(
        <>
            {
                Cargado?
                <Carousel banners={Banners}></Carousel>
                :
                <div className="bg-light rounded shadow-sm" style={{height:"300px"}}>                            
                    <div className="">
                        <LinearProgress classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}></LinearProgress>    
                    </div> 
                    <Skeleton variant="rect" className="h-100"></Skeleton>
                                    
                </div>
                
            }
        </>
    );
}