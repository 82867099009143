import axios from 'axios';
import {useParams} from 'react-router-dom'
import {useEffect,useState,React} from 'react'
import Ruta from '../Ruta'
import Titulo from '../Titulo'
import { Card, CircularProgress, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import number_format from '../../context/numberFormat'
import { Button } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import EstilosGlobales from '../../context/EstilosGlobales'
import {useHistory} from 'react-router-dom'
//
import {Helmet} from 'react-helmet-async'

/*Google Analytics */
//import {useAnalytics} from 'use-analytics'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
/*FIN Google Analytics */

/* $datoswp_array = array(
    "Nº Compra" => $venta['ID'],
    "Nombre Comercio" => "DIMASA S.A.",
    "Monto Pagado" => "$ " . number_format($venta['total_pagado'], 0, ",", "."),
    "Codigo Autorización" => $venta['authorizationCode'],
    "Fecha y Hora" => $venta['transactionDate'],
    "Tipo de pago" => $tipodepago,
    "Cuotas" => $venta['sharesNumber'],
    "Monto Cuota" => "$ " . number_format($venta['sharesAmount'], 0, ",", "."),
    "Tarjeta" => "XXXX-XXXX-XXXX-" . $venta['cardNumber']
  ); */

export default function ContenidoCarroVerEt3(){
    //const {track} = useAnalytics();
    let {token_ws} = useParams();
    let history = useHistory();

    const [Estado,setEstado] = useState("inicio");
    const [DatosVenta,setDatosVenta] = useState({});
    const [DatosWP,setDatosWP] = useState([]);
    const [URLPrint,setURLPrint] = useState("#");
    const [URLterminos_condiciones,setURLterminos_condiciones] = useState("#");
    
    
    useEffect(()=>{
        if(Estado==="inicio"){
            cargarVenta();
            cargarURLPrint();
        }
    })

    const cargarURLPrint=()=>{
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/venta/venta_print_url.php",                        
        }).then((resp)=>{
            var res = resp.data;
            if(res[0]==="realizado"){
                setURLPrint(res['url_print']);
            }
        })
    }
    
    const cargarVenta=()=>{    
        setEstado("cargando");

        var datos = new FormData();
        datos.append("token_ws",token_ws);

        axios({
            url:process.env.PUBLIC_URL+"/assets/api/venta/venta_carroet3.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            //console.log(res);
            if(res[0]==="realizado"){
                if(res.venta.pagado>0 && res.venta.total_pagado>0 && res.venta.responseCode==="0"){
                    var tipodepago = "";
                    if(res.venta.paymentTypeCode==="VD"){
                        tipodepago="Débito"
                    }else if(res.venta.paymentTypeCode==="VP"){
                        tipodepago="Prepago"
                    }else{
                        tipodepago="Crédito"
                    }
                    setDatosVenta(res);
                    setEstado("cargado");
                    setDatosWP([
                        {nombre:"Nº Compra",valor: res.venta['ID']},                       
                        {nombre:"Monto Pagado",valor: "$ " + number_format(res.venta['total_pagado'], 0, ",", ".")},
                        {nombre:"Codigo Autorización",valor: res.venta['authorizationCode']},
                        {nombre:"Fecha y Hora",valor: res.venta['transactionDate']},
                        {nombre:"Tipo de pago",valor: tipodepago},
                        {nombre:"Cuotas",valor: res.venta['sharesNumber']},
                        {nombre:"Monto Cuota",valor: "$ " + number_format(res.venta['sharesAmount'], 0, ",", ".")},
                        {nombre:"Tarjeta",valor: "XXXX-XXXX-XXXX-" + res.venta['cardNumber']}
                    ])
                    setURLterminos_condiciones(res['link_terminocondiciones']);

                    /* Tracking Google Conversion */
                    //track("comprareact",{ID:res.venta['ID']}); //Aun funciona BD
                    /*track("Venta web",{//Accion
                        category:"Venta online",//Categoria
                        label:"venta en Dimasacl",//Etiqueta
                        value:"1"
                    });*/ //Aun funciona BD
                    ReactGA.event({
                        category:"Venta",//Categoria
                        action:"purchase",
                        label:"Venta Online Web DIMASA"//Etiqueta
                    })
                    ReactPixel.track('Purchase',{value:(parseInt(res.venta['total_a_pagar'])+parseInt(res.venta['total_despacho'])),currency:"CLP"});
                    /* FIN Tracking Google Conversion */
                    
                }else{/* Reenviar ERR */
                    //REDIR
                    history.push("/carro");
                }
            }else if(res[0]==="error_sinpagar"){
                //REDIR
                    history.push("/carro");
            }else{
                //REDIR
                    history.push("/carro");
            }
        })
    }
    
    const colorPrimario = "#ff6400";
    const colorSecundario = "#1a1919";
    return(
        <div>
            {
                Estado==="cargado"?
                <div className="">
                    <Helmet>
                        <title>
                            Venta N# {`${DatosVenta.venta.ID}`} - DIMASA
                        </title>
                    </Helmet>   
                </div>
                :""
            }
            <Ruta ruta={[
                {nombre:"Inicio"},
                {nombre: "Venta Procesada"}
            ]}/>
            {
                Estado==="cargado"?
                <div className="bg-light py-3">                    
                    <div className="container">
                        <Titulo titulo="Venta Ingresada" className="py-3"></Titulo>
                        
                        <Card>
                            <div className="row justify-content-center my-3">
                                <div className="col text-center">
                                    
                                    <Typography className="font-weight-bold" variant="h4" style={{color:colorPrimario}}>
                                        Venta N# <span>{DatosVenta.venta.ID}</span>
                                    </Typography>
                                    
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col text-center">
                                    <div className="d-flex flex-row justify-content-center">
                                        <CheckCircleIcon className="text-success"></CheckCircleIcon>
                                        <Typography variant="body1" className="text-success font-weight-bold">
                                            Venta y pago procesado con éxito
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-2">
                                <div className="col text-center">
                                    <div className="d-flex flex-row justify-content-center">
                                        <LocalShippingIcon className="text-dark"></LocalShippingIcon>
                                        <Typography variant="body1" className="ml-2 text-dark font-weight-bold">
                                            Tracking ID:
                                        </Typography>                                        
                                    </div>
                                    <Typography variant="h6">
                                        {DatosVenta.venta.tracking}
                                    </Typography>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-7 col-lg-5">
                                    <div>
                                        <div className="text-center bg-dark text-white font-weight-bold py-1 border-top rounded-top shadow-sm">
                                            Datos WebPay
                                        </div>
                                        <table className="table table-borderless table-dark border-bottom rounded-bottom shadow-sm">
                                            <tbody>
                                                {
                                                    DatosWP.map((datowp,index)=>{
                                                        return(
                                                        <tr key={index}>
                                                            <td className="px-1 py-1 font-weight-bold">
                                                                {datowp.nombre}
                                                            </td>
                                                            <td className="px-1 py-1 bg-white text-dark">
                                                                {datowp.valor}
                                                            </td>
                                                        </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-end mx-md-5 mx-1">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="mr-2"> 
                                        <a rel="noreferrer" className="text-decoration-none" href={URLPrint+"?token_ws="+DatosVenta.venta.tokenWs+"&id="+DatosVenta.venta.ID} target="_blank">
                                            <Button size="medium" variant="contained" className="text-white font-weight-bold bg-danger">
                                                <PictureAsPdfIcon></PictureAsPdfIcon>
                                                <Typography variant="body2" className="ml-2">
                                                    Imprimir PDF
                                                </Typography>
                                            </Button>    
                                        </a>                                   
                                    </div>
                                    <div> 
                                        <a rel="noreferrer" className="text-decoration-none" href={URLterminos_condiciones} target="_blank">
                                            <Button size="small" variant="contained" className="text-white font-weight-bold" style={{backgroundColor:EstilosGlobales.colorSecundario}}>
                                                <PictureAsPdfIcon></PictureAsPdfIcon>
                                                <Typography variant="body2" className="ml-2">
                                                    Terminos y Condiciones 
                                                </Typography>
                                            </Button>    
                                        </a>                                   
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center py-2">
                                <div className="col-md-10 rounded shadow-sm border px-0">
                                    <div className="w-100 rounded-top py-2" style={{backgroundColor:colorPrimario}}>
                                        <Typography variant="h6" className="font-weight-bold text-white text-center">Datos Venta #{DatosVenta.venta.ID}</Typography>
                                    </div>
                                    <div className="row py-3 px-4 px-md-5"> 
                                        <div className="col-md-6">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="font-weight-bold">Nombre:</td>
                                                        <td className="px-12">{DatosVenta.venta.nombre}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-weight-bold">RUT:</td>
                                                        <td className="px-12">{DatosVenta.venta.RUT}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-weight-bold">Telefono:</td>
                                                        <td className="px-12">{DatosVenta.venta.telefono}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="font-weight-bold">Email:</td>
                                                        <td className="px-12">{DatosVenta.venta.email}</td>
                                                    </tr>
                                                    {DatosVenta.venta.tipo==="Factura"?
                                                        <>
                                                            <tr>
                                                                <td className="font-weight-bold">Tipo:</td>
                                                                <td className="px-12">{DatosVenta.venta.tipo}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Razon Social:</td>
                                                                <td className="px-12">{DatosVenta.venta.razon_social}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Email:</td>
                                                                <td className="px-12">{DatosVenta.venta.RUT_razon_social}</td>
                                                            </tr>
                                                        </>:<></>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="font-weight-bold">Tipo Despacho:</td>
                                                        <td className="px-12">
                                                            {
                                                            DatosVenta.venta.tipo_despacho==="Despacho"?
                                                            <span>Despacho a Domicilio</span>:
                                                            <span>Retiro en tienda</span>
                                                            
                                                            }
                                                        </td>
                                                    </tr>
                                                    {DatosVenta.venta.tipo_despacho==="Despacho"?
                                                        <>
                                                            <tr>
                                                                <td className="font-weight-bold">Ciudad:</td>
                                                                <td className="px-12">{DatosVenta.venta.ciudad}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Direccion:</td>
                                                                <td className="px-12">{DatosVenta.venta.direccion}</td>
                                                            </tr>  
                                                            <tr>
                                                                <td className="font-weight-bold">Plazo Despacho:</td>
                                                                <td className="px-12">
                                                                    <span className="font-weight-bold" style={{color:colorPrimario}}>
                                                                    {DatosVenta.venta.plazo_despacho}
                                                                    </span>
                                                                </td>
                                                            </tr>  
                                                        </>:<></>
                                                    }                                                  
                                                    {DatosVenta.venta["2dperson_RUT"].length>0 && DatosVenta.venta["2dperson_nombre"].length>0?
                                                        <>
                                                            <tr>
                                                                <td className="font-weight-bold">2d Nombre:</td>
                                                                <td className="px-12">{DatosVenta.venta['2dperson_nombre']}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">2d RUT:</td>
                                                                <td className="px-12">{DatosVenta.venta['2dperson_RUT']}</td>
                                                            </tr>  
                                                        </>:<></>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col px-4">
                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="text-center">SKU</th>
                                                            <th scope="col">Nombre</th>
                                                            <th scope="col" className="text-center">P/U</th>
                                                            <th scope="col" className="text-center">Cantidad</th>
                                                            <th scope="col" className="text-center">Subtotal</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            DatosVenta.productos.map((producto,index)=>{
                                                                return(
                                                                <tr key={index}> 
                                                                    <th scope="row" className="text-center" style={{color:colorPrimario}}>
                                                                        {producto.SKU}          
                                                                    </th>
                                                                    <td>{producto.nombre}</td>
                                                                    <td className="text-right">${number_format(producto.precio,0,",",".")}</td>
                                                                    <td className="text-center">{producto.cantidad}</td>
                                                                    <td className="font-weight-bold text-right">
                                                                        ${number_format(parseInt(producto.cantidad)*parseInt(producto.precio),0,",",".")}
                                                                    </td>
                                                                </tr>
                                                                );
                                                            })
                                                        }  
                                                        <tr>
                                                            <td colSpan="4" className="text-right pt-3">
                                                                <Typography variant="body1" className="font-weight-bold">Subtotal</Typography>
                                                            </td>
                                                            <td className="pt-3">
                                                                <Typography className="text-right font-weight-bold" variant="body1" style={{color:colorSecundario}}>
                                                                    ${number_format(DatosVenta.venta.total_a_pagar,0,",",".")}
                                                                </Typography>
                                                            </td>
                                                        </tr>                                                      
                                                        <tr>
                                                            <td style={{borderTop:"0px"}} colSpan="4" className="text-right">
                                                                <Typography variant="body1" color="primary" className="font-weight-bold">Despacho</Typography>
                                                            </td>
                                                            <td style={{borderTop:"0px"}}>
                                                                <Typography className="text-right font-weight-bold" variant="body1" color="primary">
                                                                    ${number_format(DatosVenta.venta.total_despacho,0,",",".")}
                                                                </Typography>
                                                            </td>
                                                        </tr>                                                      
                                                        <tr>
                                                            <td style={{borderTop:"0px"}} className="px-2" colSpan="4">
                                                                <Typography variant="h5" style={{color:colorPrimario}} className="font-weight-bold text-right">TOTAL</Typography>
                                                            </td>
                                                            <td style={{borderTop:"0px"}} className="px-2">
                                                                <Typography className="text-right font-weight-bold" variant="h5" style={{color:colorSecundario}}>
                                                                    ${number_format(parseInt(DatosVenta.venta.total_a_pagar)+parseInt(DatosVenta.venta.total_despacho),0,",",".")}
                                                                </Typography>
                                                            </td>
                                                        </tr>                                                      
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </Card>
                    </div>
                </div>
                :/* ELSE */
                <div className="bg-light py-3">                    
                    <div className="container">
                        <Titulo titulo="Cargando Venta" className="py-3"></Titulo>
                        
                        <Card className="position-relative" style={{minHeight:window.innerHeight*0.8}}>
                            <div className="d-flex flex-column align-items-center w-100 justify-content-center m-0 position-relative" style={{height:"100%",top:"80px"}}>
                                <CircularProgress style={{color:colorPrimario}}></CircularProgress>
                                <Typography variant="h6">Cargando Venta...</Typography>
                            </div>
                        </Card>
                    </div>
                </div>

            }
        </div>
    );
}
