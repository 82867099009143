import {useContext} from 'react'
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import { Card, CardActionArea, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EstilosGlobales from "../../context/EstilosGlobales";
import UsuarioClienteContext from '../../context/UsuarioClienteContext'

//Router
import {Switch,Route,Redirect,Link} from 'react-router-dom'

//Subpaginas
import MiCuenta from './ContenidoMiCuenta/MiCuenta'
import Favoritos from './ContenidoMiCuenta/Favoritos'
import Compras from './ContenidoMiCuenta/Compras'

export default function ContenidoMiCuenta() {
    const Rutas = [{ nombre: "Inicio", ruta: "/" }, { nombre: "Mi Cuenta" }];
    return (
        <div className="bg-light pb-3">
            <Ruta ruta={Rutas}></Ruta>
            <div
                className="container"
                style={{ minHeight: window.innerHeight * 0.6 }}
            >
                <Titulo className="mb-3">Mi Cuenta</Titulo>
                <div className="row">
                    <SideMenuUsuario className="col-md-3" />
                    <div className="col-md-9">
                        <Switch>
                            <Route exact path="/micuenta/perfil">
                                <MiCuenta/> 
                            </Route>
                            <Route exact path="/micuenta/favoritos">
                                <Favoritos/> 
                            </Route>
                            <Route exact path="/micuenta/compras">
                                <Compras/> 
                            </Route>
                            <Route exact path="/micuenta/*">
                                <Redirect to="/404"/>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SideMenuUsuario(props) {
    const {logoutCuenta} = useContext(UsuarioClienteContext);
    const MenuRutas = [
        {
            nombre: "Mi Cuenta",
            ruta: "/micuenta/perfil",
            icono: (
                <AccountCircleIcon
                    style={{ color: EstilosGlobales.colorPrimario }}
                    fontSize="default"
                />
            ),
        },
        {
            nombre: "Favoritos",
            ruta: "/micuenta/favoritos",
            icono: (
                <FavoriteIcon
                    style={{ color: EstilosGlobales.colorPrimario }}
                    fontSize="default"
                />
            ),
        },
        {
            nombre: "Compras",
            ruta: "/micuenta/compras",
            icono: (
                <ShoppingBasketIcon
                    style={{ color: EstilosGlobales.colorPrimario }}
                    fontSize="default"
                />
            ),
        },
    ];
    return (
        <div className={props.className}>
            <Card className="d-flex flex-column  py-3">
                {MenuRutas.map((menu, index) => {
                    return (
                        <Link className="text-decoration-none" to={menu.ruta} key={index}>
                            <CardActionArea
                                className="py-2 px-1 px-md-5"
                            >
                                <div className="d-flex flex-row align-items-center">
                                    {menu.icono}
                                    <Typography className="ml-3 text-dark">
                                        {menu.nombre}
                                    </Typography>
                                </div>
                            </CardActionArea>
                        </Link>
                    );
                })}
                <CardActionArea
                    className="py-2 px-1 px-md-5"
                    style={{ cursor: "pointer" }}
                    onClick={()=>{
                        logoutCuenta();
                    }}
                >
                    <div className="d-flex flex-row align-items-center">
                        <ExitToAppIcon
                            style={{ color: EstilosGlobales.colorPrimario }}
                            fontSize="default"
                        />
                        <Typography
                            className="ml-3"
                            style={{
                                color: EstilosGlobales.colorSecundario,
                            }}
                        >
                            Logout
                        </Typography>
                    </div>
                </CardActionArea>
            </Card>
        </div>
    );
}
