
import EstilosGlobales from '../../context/EstilosGlobales';
import { Button, Card, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
export default function SideBarFiltros(props){
    let {DatosFiltros} = props.DatosFiltros;
    let {Filtros,setFiltros} = props.Filtros;
    let {ResetFiltros} = props.ResetFiltros;
    let {setEstado} = props.Estado;

    return(
        <div className={props.className}>
            <Card className="px-2 py-1">
                <div className="row mx-1 p-2">
                    <Typography className="secondary">Filtrar por:</Typography>
                </div>
                <div className="row mx-1 mt-2 justify-content-center align-items-center">
                    <select
                    onChange={(e)=>{
                        setFiltros({...Filtros,marca:e.target.value})
                    }}
                    className="form-control mx-1 mb-1 py-0 px-1" value={Filtros.marca}>
                        <option value="">Marca</option>
                        {
                            DatosFiltros.marcas.map((marca,index)=>{
                                if(marca.marca.length>0){
                                    return(
                                        <option key={index} value={marca.marca}>{marca.marca}</option>
                                    )
                                }else{
                                    return false;
                                }
                            })
                        }
                    </select>
                </div>
                <div className="row mx-1 mt-2 justify-content-center align-items-center">
                    <select
                    onChange={(e)=>{
                        setFiltros({...Filtros,carac_principal:e.target.value})
                    }}
                    className="form-control mx-1 mb-1 py-0 px-1" value={Filtros.carac_principal}>
                        <option value="">Carac. Principal</option>
                        {
                            DatosFiltros.caracs_principales.map((carac_principal, index) => {
                                if (carac_principal.esp1!==null && carac_principal.esp1.length > 0 ) {
                                    return (
                                        <option key={index} value={carac_principal.esp1}>{carac_principal.esp1}</option>
                                    )
                                } else {
                                    return false;
                                }
                            })
                        }
                    </select>
                </div>
                <div className="row mx-1 mt-2 justify-content-center align-items-center">
                    <select
                    onChange={(e)=>{
                        setFiltros({...Filtros,carac_secundaria:e.target.value})
                    }}
                    className="form-control mx-1 mb-1 py-0 px-1" value={Filtros.carac_secundaria}>
                        <option value="">Carac. Secundaria</option>
                        {
                            DatosFiltros.caracs_secundarias.map((carac_secundaria, index) => {
                                if (carac_secundaria.esp2!==null && carac_secundaria.esp2.length > 0 ) {
                                    return (
                                        <option key={index} value={carac_secundaria.esp2}>{carac_secundaria.esp2}</option>
                                    )
                                } else {
                                    return false;
                                }
                            })
                        }
                    </select>
                </div>
                <div className="row mx-0">
{/*                     <span>Aqui ESP1 {DatosFiltros.caracs_principales[0].esp1}</span> */}
                </div>
                <div className="row mx-1">
                    <FormControlLabel
                        className="mx-1 my-0"
                        control={<Checkbox color="primary" checked={Filtros.destacado} onChange={(e)=>{setFiltros({...Filtros,destacado:e.target.checked})}}/>}
                        label="Destacados"
                        style={{marginBottom:"-10px"}}
                        />
                    <FormControlLabel
                        className="mx-1"
                        control={<Checkbox color="primary" checked={Filtros.oferta} onChange={(e)=>{setFiltros({...Filtros,oferta:e.target.checked})}}/>}
                        label="Oferta"
                        style={{marginBottom:"-10px"}}
                        />                    
                </div>
                <div className="row justify-content-end mx-1">
                    <Button onClick={() => {
                        setEstado("inicio");
                        if(Filtros.marca.length<=0 && !Filtros.destacado && !Filtros.oferta && Filtros.carac_principal.length<=0 && Filtros.carac_secundaria.length<=0)setFiltros({ ...Filtros, submited: false});
                        else setFiltros({...Filtros,submited:true});
                    }}
                        size="small" variant="contained" style={{ backgroundColor: EstilosGlobales.colorPrimario }} className="text-white mb-2 ml-2 col-md-1">Filtrar</Button>
                </div>
                {
                    Filtros.submited? 
                    <div className="row mx-1 justify-content-center align-items-center">
                        <div className="d-flex flex-row" style={{cursor:"pointer"}}
                            onClick={()=>{
                                setFiltros(ResetFiltros);
                                setEstado("inicio");
                            }} 
                        >
                            <button className="btn btn-dark font-weight-bold px-1 py-0" style={{backgroundColor:EstilosGlobales.colorSecundario}}>X</button>
                            <Typography variant="caption" className="ml-1 mt-1"><u>Quitar Filtros</u></Typography>
                        </div>
                    </div>:""
                }
            </Card>

        </div>
    );
}