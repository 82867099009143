import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoRecuperarPassConfig from '../components/cuentas/ContenidoRecuperarPassConfig.jsx'
import MetaData from '../context/MetaData'

export default function Login(){
    return(
        <div>         
            <MetaData titulo="Recuperar Contraseña"/>
            <Header>            
            </Header>
            <ContenidoRecuperarPassConfig></ContenidoRecuperarPassConfig>
            <Footer></Footer>
        </div>
    );
}
