import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoInicio from '../components/inicio/ContenidoInicio.jsx'
import {Helmet} from 'react-helmet-async'

export default function Inicio(){
    return(
        <div>         
            <Helmet>
                <title>Bienvenidos a DIMASA</title>
            </Helmet>   
            <Header>            
            </Header>
            <ContenidoInicio></ContenidoInicio>
            <Footer></Footer>
        </div>
    );
}