import { LinearProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        flexGrow: 1
    },
    colorPrimary: {
        background: "#ff9d5e"    
    },
    barColorPrimary:{
        background:"#ff6400"
    }
  });

export default function LinearProgressMine(props){
    const classes = useStyles();
    return(
        <LinearProgress className={props.className} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}></LinearProgress>
    );
}
