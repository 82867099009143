import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoContacto from '../components/contacto/ContenidoContacto.jsx'

export default function Contacto(){
    return(
        <div>
            <Header></Header>
            <ContenidoContacto></ContenidoContacto>
            <Footer></Footer>
        </div>

    );

}