import React,{useState} from 'react'
import axios from 'axios'

const UsuarioClienteContext = React.createContext();

export function UsuarioClienteProvider(props){
    const [IsLoged,setIsLoged] = useState(false);
    const [DatosUsuarioCliente,setDatosUsuarioCliente] = useState(null);

    const comprobarLogin=()=>{
        //console.log("comprobando Login");
        axios({
            url: process.env.PUBLIC_URL+"/assets/api/cuentas/comprobar_login.php"
        }).then((resp)=>{
            var res = resp.data;
            if(res[0]==="realizado"){
                //console.log("Esta Login");
                if(!IsLoged)
                    setIsLoged(true);
                if(DatosUsuarioCliente===null) 
                    setDatosUsuarioCliente({nombre:res['nombre']});
            }else{
                //console.log("No esta  Login");
                if(IsLoged)
                    setIsLoged(false);
                if(DatosUsuarioCliente!==null) 
                    setDatosUsuarioCliente(null);
            }
        })         
    }

    const logoutCuenta=()=>{
        axios({
            url: process.env.PUBLIC_URL+"/assets/api/cuentas/logout.php"
        }).then((resp)=>{
            var res = resp.data; 
            if(res[0]==="realizado"){
                setIsLoged(false);
                setDatosUsuarioCliente(null);
            }
        })
    }

    return(
        <UsuarioClienteContext.Provider value={{IsLoged,setIsLoged,DatosUsuarioCliente,setDatosUsuarioCliente,logoutCuenta,comprobarLogin}}>
            {props.children}
        </UsuarioClienteContext.Provider>
    );
}

export default UsuarioClienteContext;

