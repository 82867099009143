import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import { Card, Typography } from '@material-ui/core'

export default function ModuloInt(){
    return(
        <div>
            <Header></Header>
            <div className="bg-light">
                <div className="container py-4" style={{minHeight:window.innerHeight*0.7}}>
                    <Card style={{minHeight:window.innerHeight*0.3}}>
                        <div className="row justify-content-center">
                            <Typography className="py-3" variant="h3">
                                Modulo en Implementación
                            </Typography>                            
                        </div>
                        <div className="row justify-content-center">
                            <Typography>
                                El módulo se encuentra pendiente de implementar.
                            </Typography>
                        </div>
                    </Card>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}