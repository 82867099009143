import {useContext} from 'react'
import DatosConfigContext from './config.jsx'
import {Helmet} from 'react-helmet-async'

export default function MetaData(props){
    const {DatosConfig} = useContext(DatosConfigContext);

    var titulo="";
    var descripcion="";

    if(props.titulo){
        titulo=props.titulo;
    }
    if(props.descripcion){
        descripcion=props.descripcion;
    }
    
    return (
        <Helmet>
            {
                DatosConfig?
                <title>{`${titulo} - ${DatosConfig.Empresa.nombre}`}</title>:""
            }
            {
                props.descripcion?
                <meta name="description" content={descripcion}/>:""
            }
        </Helmet>
    );
}


