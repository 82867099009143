import Header from '../components/general/Header'
import Footer from '../components/general/Footer'

import ContenidoCarroVerEt3 from '../components/carro/ContenidoCarroVerEt3'

/* Enviar Mail en esta vuelta. Por detras*/

export default function CarroVerEt3(){    

    return(
        <div>
            <Header></Header>
            <ContenidoCarroVerEt3></ContenidoCarroVerEt3>
            <Footer></Footer>
        </div>
    );
}