import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoBlogPost from '../components/blog/ContenidoBlogPost'

export default function BlogPost(){
    return(
        <div>
            <Header></Header>
            <ContenidoBlogPost></ContenidoBlogPost>
            <Footer></Footer>
        </div>
    );
}

