
import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoProductoVer from '../components/productover/ContenidoProductoVer.jsx'

export default function Producto(){
    return (
        <div>
            <Header></Header>
            <ContenidoProductoVer></ContenidoProductoVer>
            <Footer></Footer>
            
        </div>
    );
}