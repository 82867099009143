import 'fontsource-roboto';

import React, {useState,useContext,useEffect }/* , { useState, useEffect, useContext } */ from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
/* import axios from 'axios' */
import { BrowserRouter, Redirect, Route, Switch /*, Redirect */ } from 'react-router-dom'
import "./App.css"
import {HelmetProvider} from 'react-helmet-async'

/* Paginas */
import Inicio from './pages/Inicio'
import NoEncontrado from './pages/NoEncontrado'
import Superfamilias from './pages/Superfamilias'
import Productos from './pages/Productos'
import ProductoVer from './pages/ProductoVer'
import Buscar from './pages/Buscar'
import Acercaden from './pages/Acercaden'
import CarroVer from './pages/CarroVer'
import CarroVerEt2 from './pages/CarroVerEt2'
import CarroVerEt3 from './pages/CarroVerEt3'
import CarroVerEt3Fail from './pages/CarroVerEt3Fail'
import Contacto from './pages/Contacto'
import Tracking from './pages/Tracking'
import ModuloInt from './pages/ModuloInt'
import ErrorCompra from './pages/ErrorCompra.jsx'

/* Cuentas */
import Login from './pages/Login.jsx'
import Registrar from './pages/Registrar.jsx'
import ActivarCuenta from './pages/ActivarCuenta.jsx'
import RecuperarPass from './pages/RecuperarPass'
import RecuperarPassConfig from './pages/RecuperarPassConfig'
import MiCuenta from './pages/MiCuenta'
//Context
import {UsuarioClienteProvider} from './context/UsuarioClienteContext'
import UsuarioClienteContext from './context/UsuarioClienteContext'
/* FIN Cuentas */

/* Blog */
import Blog from './pages/Blog.jsx'
import BlogPost from './pages/BlogPost.jsx'
import BlogSec from './pages/BlogSec.jsx'
/* FIN Blog */

/* Mantencion */
import Mantencion from './pages/Mantencion'


/* Componentes */
import Whatsapp from './components/Whatsapp';
import AdminLogeadoPrint from './components/AdminLogeadoPrint';

/* Variables Globales Context */
import Carro from './context/Carro.jsx'
import axios from 'axios';
import {CircularProgress, Typography} from '@material-ui/core';
import EstilosGlobales from './context/EstilosGlobales'

/* CYBER MODE */
import {CyberModeProvider} from './context/CyberModeContext'
import CyberModeContext from './context/CyberModeContext'
/* /CYBER MODE */

/* /Google Analytics*/
import ReactGA from 'react-ga4'

/* /FACEBOOK PIXEL*/
import ReactPixel from 'react-facebook-pixel'

/* Import DatosConfig General */
import {DatosConfigProvider} from './context/config.jsx'
/* Import DatosConfig General */



export default function AppFn2(){
    return (
        <DatosConfigProvider>
            <AppFn>
            </AppFn>
        </DatosConfigProvider>
    );
}

function AppFn() {
    const [Cargado,setCargado] = useState(false);
    const [AnalyticsConfig,setAnalyticsConfig] = useState();

    /* PIXEL FACEBOOK*/
    //const advancedMatching = { em: 'correo@asd.cl' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

    useEffect(()=>{
        const iniciarReactPixel=()=>{
            axios({
                url: process.env.PUBLIC_URL + "/assets/api/crm/pixelfb.php",
            }).then((resp)=>{
                var res = resp.data;

                if(res[0]==="realizado"){
                    //alert("ID PIXEL:"+res['id_pixel']+" ID Analytics:"+res['id_google_analytics']);
                    //alert(res['correo_advance_matching']);
                    //Google Analytics
                    ReactGA.initialize(res['id_google_analytics']);
                    ReactGA.send(window.location.pathname+window.location.search);

                    //Pixel Facebook
                    ReactPixel.init(res['id_pixel'],{em:res['correo_advance_matching']},{autoConfig:true,debug:false});
                    ReactPixel.pageView();

                    setCargado(true);
                }
                
            })

        }
        iniciarReactPixel();
    },[])

    /* FIN PIXEL FACEBOOK */


    /*useEffect(()=>{
            axios({
                url: process.env.PUBLIC_URL + "/assets/api/global/config.json",
            }).then((resp)=>{
                var res = resp.data;
                if(res){
                    setCargado(true);
                    setAnalyticsConfig(Analytics({
                        app: 'web_tor_app',
                            plugins: [
                            googleAnalytics({
                                trackingId: res.API_config.googleAnalytics.trackingId
                            })
                        ]
                    }))
                }
            })  
        
    },[])*/


    if(Cargado){
        return(
                <CyberModeProvider> 
                    <UsuarioClienteProvider>
                        <App>
                        </App>
                    </UsuarioClienteProvider>
                </CyberModeProvider> 
        );
    }else{
        return (<></>);
    }
}

function App() { 
    const {IsLoged,comprobarLogin} = useContext(UsuarioClienteContext);
    const {comprobarCyberMode} = useContext(CyberModeContext);

    const [DatosCarro,setDatosCarro] = useState({
        cantidad_productos:0,
        total:0
    });

    const [EnProduccion,setEnProduccion] = useState();
    const [AdminLogeado,setAdminLogeado] = useState(false);
    const [Cargado,setCargado] = useState(false);

    const ActualizarCarro=()=>{
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/carrodn/carrodn_info.php"
        }).then((resp)=>{
            var res = resp.data;
            setDatosCarro({
                cantidad_productos:res['cantidad_productos'],
                total:res['total']
            });
        })
    }

    const comprobarMantecion=()=>{
        //console.log("Comp Mant");
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/mantencion_comp.php"
        }).then((resp)=>{
            const res = resp.data;
            if(res[0]==="realizado"){
                if(parseInt(res['web_operativa'])>0) {
                    setEnProduccion(true);
                    setCargado(true);
                }else{
                    setEnProduccion(false);
                    setCargado(true);
                }
                if(parseInt(res['admin_logeado'])>0){
                    setAdminLogeado(true);
                }else{
                    setAdminLogeado(false);
                }
            }else{
                setEnProduccion(false);
                setCargado(true);
                setAdminLogeado(false);
            }
        })
    }

    useEffect(()=>{
        comprobarLogin();
        comprobarMantecion();
        comprobarCyberMode();
    })

    useEffect(()=>{
        ReactGA.send(window.location.pathname+window.location.search);
        ReactPixel.pageView();
    })

    if((Cargado && EnProduccion) || (Cargado && !EnProduccion && AdminLogeado)){
        return (
            <Carro.Provider value={{ActualizarCarro,DatosCarro}}>
                <HelmetProvider>
                        {
                            AdminLogeado?
                            <AdminLogeadoPrint/>:""
                        }
                        <Whatsapp></Whatsapp>                
                        <BrowserRouter basename="/">
                            <Switch>
                                <Route exact path="/" component={Inicio} />                
                                <Route exact path="/404" component={NoEncontrado} />                
                                <Route exact path="/superfamilias/:superfamilia" component={Superfamilias} />                
                                <Route exact path="/productos/:superfamilia/:familia/:subfamilia" component={Productos} />                
                                <Route exact path="/producto/:SKU" component={ProductoVer} />                
                                <Route exact path="/buscar/:busqueda" component={Buscar} />                
                                <Route exact path="/acercaden" component={Acercaden} />                
                                <Route exact path="/contacto" component={Contacto} />                
                                <Route exact path="/carro" component={CarroVer} />                
                                <Route exact path="/carroet2" /* component={CarroVerEt2} */>
                                    {DatosCarro.cantidad_productos>0?
                                        <CarroVerEt2></CarroVerEt2>:<Redirect to="/carro"></Redirect>
                                    }    
                                </Route>                
                                <Route exact path="/carroet3/:token_ws" component={CarroVerEt3} />                
                                <Route exact path="/carroet3fail/:tipoerror/:token_ws" component={CarroVerEt3Fail} />                
                                <Route exact path="/tracking" component={Tracking} />                
                                <Route exact path="/moduloint" component={ModuloInt} />                
                                <Route exact path="/cuenta_activar/:id_activacion" component={ActivarCuenta} />
                                <Route exact path="/blog/:categoria_blog_id" component={Blog} />
                                <Route exact path="/blogpost/:ID_post" component={BlogPost} />
                                <Route exact path="/blogsec" component={BlogSec} />
                                <Route exact path="/error_compra/:tipo_error" component={ErrorCompra}/>

                                {/* PATHs SOLO sin Login*/}
                                <Route exact path="/login">
                                    {!IsLoged?<Login/>:<Redirect to="/"/>}
                                </Route>
                                <Route exact path="/registrar">
                                    {!IsLoged?<Registrar/>:<Redirect to="/"/>}
                                </Route>
                                <Route exact path="/recuperar_pass">
                                    {!IsLoged?<RecuperarPass/>:<Redirect to="/"/>}
                                </Route>
                                <Route exact path="/recuperar_pass_config/:id_recuperacion">
                                    {!IsLoged?<RecuperarPassConfig/>:<Redirect to="/"/>}
                                </Route>
                                {/* PATHs logeado */}
                                <Route path="/micuenta">
                                    {IsLoged?<MiCuenta/>:<Redirect to="/login"/>}
                                </Route>
                                <Route path="*" component={NoEncontrado} />                
                            </Switch>
                        </BrowserRouter>                 
                </HelmetProvider>
            </Carro.Provider>
        );
        
    }else if(Cargado && !EnProduccion){
        return(
            
            <Carro.Provider value={{ActualizarCarro,DatosCarro}}>
                <HelmetProvider>
                        <Whatsapp></Whatsapp>                
                        <BrowserRouter basename="/">
                        <Switch>
                            <Route exact path="/carroet3/:token_ws" component={CarroVerEt3} />                
                            <Route exact path="/carroet3fail/:tipoerror/:token_ws" component={CarroVerEt3Fail} />                
                            <Route exact path="/error_compra/:tipo_error" component={ErrorCompra}/>
                            <Route exact path="/" component={Mantencion} />                
                            <Route path="*" component={Mantencion} />                
                        </Switch>
                        </BrowserRouter>                 
                </HelmetProvider>
            </Carro.Provider>
        );
    }else{//Cargando
        return(
            <div className="container">
                <div className="row justify-content-center align-items-center" style={{height:window.innerHeight}}>
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <div className="">
                            <img className="img-fluid" src={ process.env.PUBLIC_URL+"/assets/imagenes/logof.png" } alt="..." style={{maxWidth:"220px",color:EstilosGlobales.colorPrimario}}/>
                        </div>
                        <CircularProgress className="mt-2" style={{color:EstilosGlobales.colorPrimario}}/>
                        <Typography>
                            Cargando...
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}
