import { Typography } from '@material-ui/core';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Titulo from '../Titulo'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';


function Esqueleto(props) {
    return (
        <div className={props.className}>
            <Skeleton height={300}></Skeleton>
        </div>
    );
}

export default function CategoriaProductos(){

    const [Estado,setEstado] = useState("inicio");
    const [Superfamilias,setSuperfamilias] = useState([{}]);
    const ClaseSuperfamilia = "col-6 col-md-4 col-lg-4 col-xl-3 my-2"

    useEffect(()=>{
        if(Estado==="inicio"){
            cargarSuperfamilias();
        }
    })

    const cargarSuperfamilias=()=>{
        setEstado("cargando");
        axios({
            url:process.env.PUBLIC_URL+"/assets/api/superfamilias_cargar.php"            
        }).then((resp)=>{
            var res = resp.data;            
            setEstado("cargado"); 
            setSuperfamilias(res);
        })
    }

    return(
        <div className="my-3">
            <Titulo titulo="CATEGORÍA DE PRODUCTOS"></Titulo>
                {
                    Estado==="cargado"?
                    <div className="row my-2">
                        {
                            Superfamilias.map((superfamilia,index)=>{
                                return(
                                    <div key={index} className={ClaseSuperfamilia}>
                                        <Link className="text-decoration-none" to={"/superfamilias/"+superfamilia.superfamilia}>
                                            <Card>
                                                <CardActionArea className="px-2 px-sm-3 px-md-5 py-2">
                                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                                        
                                                        <img className="img-fluid" src={process.env.PUBLIC_URL+"/assets/imagenes/superfamilias/"+superfamilia.imagen} alt={superfamilia.superfamilia}></img>
                                                        
                                                        <Typography style={{color:"#FF6400",minHeight:"3em"}} className="text-center font-weight-bold">{superfamilia.superfamilia}</Typography>
                                                        
                                                    </div>
                                                </CardActionArea>
                                            </Card>  
                                        </Link>            
                                    </div>
                                );
                            })
                        }
                    </div>
                    :
                    <div className="row">
                        <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                        <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                        <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                        <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                        <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                        <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                        <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                        <Esqueleto className={ClaseSuperfamilia}></Esqueleto>
                    </div>
                }
        </div>
    );
}