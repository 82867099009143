import {useState} from 'react'
import { Typography,Button } from "@material-ui/core"
import './MenuDown.css'
import MenuCategorias from './MenuCategorias'
import ListIcon from '@material-ui/icons/List';
import {Link} from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';


export default function MenuDown(){
    const ClaseBotones = "botonMenuDown text-white mr-1 py-2"
    const Menu = [
        {titulo:"Inicio",linkto:"/"},
        {titulo:"Secciones",linkto:"/blogsec"},
        {titulo:"Acerca de Nosotros",linkto:"/acercaden"},
        /* {titulo:"Productos",linkto:"/productos"}, */
        {titulo:"Contacto",linkto:"/contacto"},
        {titulo:"Tracking",linkto:"/tracking"}
    ]
    const propsanim = useSpring({ opacity: 1, from: { opacity: 0 } });
    const [DropPrincipal,setDropPrincipal] = useState(false);

    const togleDropPrincipal=()=>{
        setDropPrincipal(!DropPrincipal);
    }

    return(
        <div className="container-fluid barra_menu" style={{paddingLeft:"0px",paddingRight:"0px",backgroundColor:"#1a1919"}}>
            <div className="container">
                <div className="row justify-content-between justify-content-lg-center py-0">
                    <MenuCategorias></MenuCategorias>
                    <div className="py-1 d-none d-lg-block">
                        {
                            Menu.map((itemMenu,index)=>{
                                return(
                                    <Link className="text-decoration-none" key={index} to={itemMenu.linkto}>
                                        <Button className={ClaseBotones}>
                                            <Typography variant="body2">
                                                {itemMenu.titulo}
                                            </Typography>
                                        </Button>
                                    </Link>
                                )
                            })
                        }
                    </div>
                    <div className="py-1 d-block d-lg-none position-relative">
                        <Button onClick={togleDropPrincipal} className={ClaseBotones+" position-relative"}>
                            
                            {
                                DropPrincipal?  
                                <animated.div style={propsanim}>
                                    <CloseIcon></CloseIcon>
                                </animated.div>                              
                                :
                                <animated.div style={propsanim}>
                                    <ListIcon></ListIcon>
                                </animated.div>                              
                            }
                        </Button>  
                        {
                            DropPrincipal?
                            <ClickAwayListener onClickAway={togleDropPrincipal}>
                                <div className="position-absolute bg-light shadow rounded py-2 px-4" style={{top:"45px",right:"20px",zIndex:"1000"}}>
                                    <animated.div style={propsanim}>
                                        {
                                            Menu.map((itemMenu,index)=>{
                                                return(
                                                    <Link className="text-decoration-none" key={index} to={itemMenu.linkto}>
                                                        <Button className={"text-dark"}>
                                                            <Typography variant="body2">
                                                                {itemMenu.titulo}
                                                            </Typography>
                                                        </Button>
                                                    </Link>
                                                )
                                            })
                                        }
                                    </animated.div>
                                </div> 
                            </ClickAwayListener>  
                            :""
                        }                   
                    </div>
                </div>
            </div>            
        </div>
    );
}
