import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoProductos from '../components/productos/ContenidoProductos'

export default function Productos(){
    return(
        <div>
            <Header></Header>
            <ContenidoProductos></ContenidoProductos>
            <Footer></Footer>
        </div>
    );
}

