import { Button, Card, TextField, Typography } from "@material-ui/core";
import EstilosGlobales from "../../context/EstilosGlobales";
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import {Alert} from '@material-ui/lab'
import LinearProgressMine from '../LinearProgressMine.jsx'

export default function ContenidoLogin() {
    const Rutas = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Recuperar Contraseña" },
    ];
    const ClaseInputs = "col-md-6";
    const [DatosRecup, setDatosRecup] = useState({
        email: "",
        email_confirmar: "",
    });
    const [ErrorCampos, setErrorCampos] = useState({
        email: { error: false, texto: "" },
        email_confirmar: { error: false, texto: "" },
    });
    const [EstadoSubmited, setEstadoSubmited] = useState(false);
    const [RecuperarSuccess, setRecuperarSuccess] = useState(false);
    const [PrintConsole,setPrintConsole]=useState();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (EstadoSubmited) {
            //Si esta en submit
            return false;
        }

        var campos_requeridos = 0;
        var MsjeCampoReq = "Campo requerido";
        var ErrorCampoPre = {
            email: { error: false, texto: "" },
            email_confirmar: { error: false, texto: "" },
        };

        if (DatosRecup.email.length <= 0) {
            ErrorCampoPre.email.error = true;
            ErrorCampoPre.email.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else ErrorCampoPre.email.error = false;

        if (DatosRecup.email_confirmar.length <= 0) {
            ErrorCampoPre.email_confirmar.error = true;
            ErrorCampoPre.email_confirmar.texto = MsjeCampoReq;
            campos_requeridos += 1;
        } else if (
            DatosRecup.email_confirmar.length > 0 &&
            DatosRecup.email !== DatosRecup.email_confirmar
        ) {
            ErrorCampoPre.email_confirmar.error = true;
            ErrorCampoPre.email_confirmar.texto = "Emails No coinciden.";
            campos_requeridos += 1;
        } else ErrorCampoPre.email_confirmar.error = false;

        setErrorCampos(ErrorCampoPre);
        if (campos_requeridos > 0) {
            return false;
        } else {
            setEstadoSubmited(true);
        }

        var datos = new FormData();
        datos.append("email", DatosRecup.email);

        axios({
            url:
                process.env.PUBLIC_URL +
                "/assets/api/cuentas/recuperar_pass.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            //console.log(res);
            if (res[0] === "realizado") {
                setRecuperarSuccess(true);
            } else if (res[0] === "no_enviado") {
                setPrintConsole(<Alert severity="warning">La recuperación no fue enviada debido a un error inesperado. Recargue e intente nuevamente. Si el error persiste, contáctese con soporte.</Alert>)
            } else if (res[0] === "error_frecuencia") {
                setEstadoSubmited(false);
                setPrintConsole(<Alert severity="error">Ya fueron enviados datos de recuperación a este email. Debe esperar al menos 15 minutos para iniciar un nuevo proceso de recuperación.</Alert>)
            } else if (res[0] === "no_existe_cuenta") {
                setEstadoSubmited(false);
                setPrintConsole(<Alert severity="error">No existe una cuenta asociada a este <b>email</b>.</Alert>)
            } else if (res[0] === "error") {
                setPrintConsole(<Alert severity="warning">Ocurrió un error inesperado. Recargue e intente nuevamente. Si el error persiste, contáctese con soporte.</Alert>)
            }else{

                setPrintConsole(<Alert severity="warning">Ocurrió un error inesperado. Recargue e intente nuevamente. Si el error persiste, contáctese con soporte.</Alert>)
            }
        });
    };

    return (
        <div>
            <Ruta ruta={Rutas}></Ruta>
            <div className="bg-light">
                <div
                    className="container py-2"
                    style={{ minHeight: window.innerHeight * 0.6 }}
                >
                    <Titulo titulo="Cuenta"></Titulo>
                    <div className="row py-4 justify-content-center">
                        <div className="col-md-8 col-sm-10 col-12">
                            <Card className="py-4 px-2 text-center">
                                <Titulo className="px-5 mb-4">Recuperar Contraseña</Titulo>
                                {
                                    RecuperarSuccess?
                                    <div style={{minHeight:"220px"}}>
                                        <Typography>
                                            Se ha enviado un correo electrónico a <span className="font-weight-bold" style={{color:EstilosGlobales.colorPrimario}}>{DatosRecup.email}</span> con los datos para recuperar la contraseña.
                                        </Typography>
                                        <Typography variant="caption" className="font-weight-bold text-secondary">
                                            NOTA: Si no visualiza el correo, revise su bandeja de Spam.
                                        </Typography>
                                    </div>
                                    :
                                    <FormularioRecuperarPass 
                                        handleSubmit={{handleSubmit}}
                                        DatosRecup={{DatosRecup,setDatosRecup}}
                                        ClaseInputs={{ClaseInputs}}
                                        ErrorCampos={{ErrorCampos}}
                                        PrintConsole={PrintConsole}
                                        EstadoSubmited={EstadoSubmited}
                                    />
                                }
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function FormularioRecuperarPass(props) {
    let {handleSubmit}=props.handleSubmit;
    let {DatosRecup,setDatosRecup}=props.DatosRecup;
    let {ClaseInputs}=props.ClaseInputs;
    let {ErrorCampos}=props.ErrorCampos;
    let {PrintConsole}=props;
    let {EstadoSubmited}=props;
    return (
        <form
            onSubmit={(e) => {
                handleSubmit(e);
            }}
        >
            <Typography>
                Ingrese su email para inicializar el proceso de recuperación de
                contraseña.
            </Typography>
            <div className="row mb-3 justify-content-center mt-2">
                <div className={ClaseInputs}>
                    <TextField
                        type="email"
                        label="Email"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e) => {
                            setDatosRecup({
                                ...DatosRecup,
                                email: e.target.value,
                            });
                        }}
                        error={ErrorCampos.email.error}
                        helperText={ErrorCampos.email.texto}
                    ></TextField>
                </div>
            </div>
            <div className="row my-1 justify-content-center">
                <div className={ClaseInputs}>
                    <TextField
                        type="email"
                        label="Confirme Email"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={(e) => {
                            setDatosRecup({
                                ...DatosRecup,
                                email_confirmar: e.target.value,
                            });
                        }}
                        error={ErrorCampos.email_confirmar.error}
                        helperText={ErrorCampos.email_confirmar.texto}
                    ></TextField>
                </div>
            </div>
            <div className="row justify-content-center mt-1">
                {PrintConsole}
            </div>
            {
                EstadoSubmited?
                <div className="">
                    <LinearProgressMine className="mt-2"/>
                    <Typography className="font-weight-bold" style={{color:EstilosGlobales.colorPrimario}}>Cargando...</Typography>
                </div>
                :""
            }
            <div className="row mt-3 justify-content-center">
                <div className={ClaseInputs}>
                    <Button
                        type="submit"
                        className="text-white"
                        variant="contained"
                        size="large"
                        style={{
                            backgroundColor: EstilosGlobales.colorPrimario,
                        }}
                    >
                        Recuperar
                    </Button>
                </div>
            </div>
            <div className="d-flex flex-column mt-2">
                <Typography variant="body2">
                    ¿ No tienes cuenta ?{" "}
                    <Link
                        className="ml-1 text-decoration-none font-weight-bold"
                        style={{
                            color: EstilosGlobales.colorPrimario,
                        }}
                        to="/registrar"
                    >
                        Registrarme
                    </Link>
                </Typography>
            </div>
        </form>
    );
}
