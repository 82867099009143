import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoCarroVerEt3Fail from '../components/carro/ContenidoCarroVerEt3Fail.jsx'

export default function CarroVerEt3Fail(){
    return(
        <div>
            <Header></Header>
            <ContenidoCarroVerEt3Fail></ContenidoCarroVerEt3Fail>
            <Footer></Footer>
        </div>
    );
}