import {useState,useEffect} from 'react'
import {Card,CircularProgress,Typography} from '@material-ui/core'
import axios from 'axios'
import Producto from '../../Producto.jsx'
import { Pagination } from '@material-ui/lab';
export default function Favoritos(){

    const [Cargado,setCargado] = useState("inicio");
    const [ProductosFavoritos,setProductosFavoritos] = useState([]);

    const [DatosCarga, setDatosCarga] = useState({
        limite_pagina: 9,
        pagina: 1,
        cant_paginas: 1,
        cant_total: 1,
    })

    useEffect(() => {
        if(Cargado==="inicio")cargarFavoritos();
    })


    const cargarFavoritos=()=>{
        setCargado("cargando");
        var datos = new FormData();
        datos.append("limite_pagina",DatosCarga.limite_pagina);
        datos.append("pagina",DatosCarga.pagina);

        axios({
            url: process.env.PUBLIC_URL+"/assets/api/cuentas/productos_fav_cargar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res=resp.data;
            //console.log(res);
            setCargado("cargado");
            setProductosFavoritos(res['productos']);
            setDatosCarga({
                ...DatosCarga,
                cant_paginas:res['cant_paginas'],
                cant_total:res['cant_total']
            })
        })
    }

    const ClaseProducto = "px-1 px-sm-2 col-6 col-md-4 my-2"
    
    if(Cargado==="cargado"){
        return(
            <Card className="px-4">
                {
                    ProductosFavoritos && ProductosFavoritos.length>0?
                    <div className="row">
                        {
                            ProductosFavoritos.map((producto,index)=>{
                                return(
                                    <Producto DatosProducto={producto} key={index} className={ClaseProducto}/> 
                                )
                            })
                        }      
                    </div>: 
                    <div className="row justify-content-center align-items-center py-5">
                        <Typography>
                            Lista de Favoritos Vacía.
                        </Typography>
                    </div>
                }
                <div className="row pb-3 pt-2 justify-content-center">
                    <Pagination
                        onChange={(e, pag_dest) => {
                            setDatosCarga({ ...DatosCarga, pagina: pag_dest });
                            setCargado("inicio");
                        }}
                        count={DatosCarga.cant_paginas} color="primary"
                        page={DatosCarga.pagina}
                    >
                        
                    </Pagination>
                </div>
            </Card>
        );
    }else{
        return(
            <Card style={{minHeight:"384px"}}> 
                <div className="row justify-content-center">
                    <div className="d-flex flex-row align-items-center mt-5">
                        <CircularProgress/>
                        <Typography className="ml-2">
                            Cargando ....
                        </Typography>
                    </div> 
                </div>
            </Card> 
        );
    }
}
