import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoBuscar from '../components/buscar/ContenidoBuscar.jsx'

export default function Buscar(){
    return(
        <div>
            <Header></Header>
            <ContenidoBuscar></ContenidoBuscar>
            <Footer></Footer>
        </div>
    );
}