import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoTracking from '../components/tracking/ContenidoTracking'

export default function Tracking(){
    return(
        <div>
            <Header></Header>
            <ContenidoTracking></ContenidoTracking>
            <Footer></Footer>
        </div>
    );
}