import { useEffect, useState } from "react";
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import axios from "axios";
import CardDn3 from "../CardDn3";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

export default function ContenidoBlogSec() {
    const [Rutas] = useState([
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Blog Secciones" },
    ]);
    const [CategoriasBlog, setCategoriasBlog] = useState([]);
    const [Cargado, setCargado] = useState(false);

    useEffect(() => {
        const cargarCategorias = () => {
            axios({
                url:
                    process.env.PUBLIC_URL +
                    "/assets/api/blog/cargar_categorias.php",
            }).then((resp) => {
                var res = resp.data;
                setCategoriasBlog(res);
                setCargado(true);
            });
        };
        cargarCategorias();
    }, []);

    var ClaseSkeleton = "col-md-4 my-2";
    return (
        <div>
            <Ruta ruta={Rutas}></Ruta>
            <div
                className="bg-light"
                style={{ minHeight: window.innerHeight * 0.6 }}
            >
                <div className="container">
                    <Titulo className="pt-3">Nuestras Secciones</Titulo>
                    {Cargado ? (
                        <div className="row py-3">
                            {CategoriasBlog.map((categoria, index) => {
                                return (
                                    <Link
                                        key={index}
                                        to={"/blog/" + categoria.ID}
                                        className="col-md-4 my-2 text-decoration-none"
                                    >
                                        <CardDn3
                                            titulo={categoria.nombre}
                                            imagen={
                                                process.env.PUBLIC_URL +
                                                "/assets/imagenes/blog/categorias/" +
                                                categoria.imagen
                                            }
                                            botonTexto="Ver más"
                                        ></CardDn3>
                                    </Link>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="row py-3">
                            <Esqueleto className={ClaseSkeleton}/>
                            <Esqueleto className={ClaseSkeleton}/>
                            <Esqueleto className={ClaseSkeleton}/>
                            <Esqueleto className={ClaseSkeleton}/>
                            <Esqueleto className={ClaseSkeleton}/>
                            <Esqueleto className={ClaseSkeleton}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

function Esqueleto(props) {
    return (
        <div className={props.className}>
            <Skeleton variant="rect" height={350} style={{borderRadius:"12px 12px 12px 12px"}} />
        </div>
    );
}
