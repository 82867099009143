import { Button, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { Link } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MenuDown from "./Header/MenuDown";
import MenuBlog from "./Header/MenuBlog";
import MenuUsuario from "./Header/MenuUsuario";
import "./Header.css";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import { Redirect } from "react-router-dom";
import InstagramIcon from "@material-ui/icons/Instagram";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import EstilosGlobales from "../../context/EstilosGlobales";

//CYBER MODE
import CyberModeContext from '../../context/CyberModeContext'

//Contexts
import Carro from "../../context/Carro";
import UsuarioClienteContext from "../../context/UsuarioClienteContext";

import number_format from "../../context/numberFormat";

export default function App(props) {
    const { DatosUsuarioCliente, IsLoged } = useContext(UsuarioClienteContext);

    const { DatosCarro, ActualizarCarro } = useContext(Carro);

    const {CyberMode,DatosCyberMode} = useContext(CyberModeContext);

    const clasesIconos = "mr-1";

    const [sugerenciasBusqueda, setsugerenciasBusqueda] = useState([]);
    const [Busqueda, setBusqueda] = useState("");
    const [BusquedaSubmited, setBusquedaSubmited] = useState("");

    const [EstadoActualizacion, setEstadoActualizacion] = useState("inicio");
    const [EstadoRedes, setEstadoRedes] = useState("inicio");

    const [DatosEmpresa, setDatosEmpresa] = useState({
        telefono: "",
        email: "",
        nombre: "",
        whatsapp: "",
        direccion: "",
        website: "",
        linkedin: "",
        facebook: "",
        youtube: "",
        instagram: "",
    });

    useEffect(() => {
        if (EstadoActualizacion === "inicio") {
            ActualizarCarroLocal();
        }
        if (EstadoRedes === "inicio") {
            cargarRedes();
        }
    });

    const cargarRedes = () => {
        setEstadoRedes("cargando");
        axios({
            url: process.env.PUBLIC_URL + "/assets/api/global/config.json",
        }).then((resp) => {
            var res = resp.data;
            setDatosEmpresa(res.Empresa);
            setEstadoRedes("cargado");
        });
    };

    const ActualizarCarroLocal = () => {
        setEstadoActualizacion("cargado");
        ActualizarCarro();
        /* console.log("Se actualizo carro"); */
    };

    const SugerirBusqueda = (Texto) => {
        if (Texto.length < 4) {
            return false;
        }

        var datos = new FormData();

        datos.append("accion", "buscar");
        datos.append("busqueda", Texto);

        axios({
            url: process.env.PUBLIC_URL + "/assets/api/buscar.php",
            method: "post",
            data: datos,
        }).then((resp) => {
            var res = resp.data;
            /* console.log(res); */
            setsugerenciasBusqueda(res);
        });
    };

    const buscarSubmit = (e) => {
        e.preventDefault();
        if (Busqueda.length <= 0) {
            return false;
        }
        /* console.log("Buscar: "+Busqueda); */
        setSubmitBuscar(true);
        setBusquedaSubmited(Busqueda);
    };

    const [SubmitBuscar, setSubmitBuscar] = useState(false);
    const [MenuUsuarioDeploy, setMenuUsuarioDeploy] = useState(false);
    return (
        <div>
            {SubmitBuscar ? (
                <Redirect
                    to={"/buscar/" + encodeURIComponent(BusquedaSubmited)}
                ></Redirect>
            ) : (
                ""
            )}
            <div className="container-fluid bg-light border-bottom">
                <div className="row justify-content-between py-1 px-4">
                    <div>
                        <Typography variant="caption">
                            Compras Call Center:{" "}
                            <a
                                href={"tel:" + DatosEmpresa.telefono}
                                className="text-decoration-none text-dark font-weight-bold"
                            >
                                {DatosEmpresa.telefono}
                            </a>
                        </Typography>
                    </div>
                    <MenuBlog />
                    <div className="d-flex flex-row align-items-center">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={
                                DatosEmpresa.whatsapp
                            }
                            className={
                                clasesIconos +
                                " text-decoration-none btn btn-success py-0 px-2 d-flex flex-row align-items-center"
                            }
                        >
                            <WhatsAppIcon></WhatsAppIcon>
                            <Typography
                                className="font-weight-bold"
                                variant="caption"
                            >
                                Whatsapp
                            </Typography>
                        </a>
                        <LineaVertical></LineaVertical>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={DatosEmpresa.facebook}
                            className={clasesIconos}
                        >
                            <FacebookIcon
                                style={{ color: "#2D88FF" }}
                            ></FacebookIcon>
                        </a>
                        <LineaVertical></LineaVertical>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={DatosEmpresa.youtube}
                            className={clasesIconos}
                        >
                            <YouTubeIcon
                                style={{ color: "#FF0000" }}
                            ></YouTubeIcon>
                        </a>
                        <LineaVertical></LineaVertical>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={DatosEmpresa.linkedin}
                            className={clasesIconos}
                        >
                            <LinkedInIcon
                                style={{ color: "#0866C2" }}
                            ></LinkedInIcon>
                        </a>
                        <LineaVertical></LineaVertical>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={DatosEmpresa.instagram}
                            className={clasesIconos}
                        >
                            <InstagramIcon
                                style={{ color: "#ED4956" }}
                            ></InstagramIcon>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row py-2 align-items-center">
                    <div className="col-md-4">
                        <div style={{ maxWidth: "300px" }}>
                            {
                                CyberMode?
                                <Link to="/">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/assets/imagenes/cyber/"+
                                            DatosCyberMode.cyber_logo
                                        }
                                        alt=""
                                        className="img-fluid"
                                    />
                                </Link>:
                                <Link to="/">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/assets/imagenes/logof.png"
                                        }
                                        alt=""
                                        className="img-fluid"
                                    />
                                </Link>
                            }
                        </div>
                    </div>
                    <div className="col-md-4">
                        <form
                            onSubmit={(e) => {
                                buscarSubmit(e);
                            }}
                            className="d-flex flex-row align-items-center"
                        >
                            <Autocomplete
                                className="w-100"
                                id="buscador"
                                freeSolo
                                options={sugerenciasBusqueda.map(
                                    (sugerencia) => sugerencia
                                )}
                                onInputChange={(e, inputValue) => {
                                    SugerirBusqueda(inputValue);
                                    setBusqueda(inputValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        /* onChange={(e)=>{SugerirBusqueda(e.target.value);setBusqueda(e.target.value)}} */ style={{
                                            borderColor: "#ff6400",
                                        }}
                                        {...params}
                                        label="Encuentra tu producto"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                )}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                style={{
                                    height: "56px",
                                    backgroundColor: "#ff6400",
                                    marginLeft: "-5px",
                                }}
                                className="mt-2 p-0 m-0"
                            >
                                <SearchIcon className="text-white"></SearchIcon>
                            </Button>
                        </form>
                    </div>
                    <div className="col-md-4">
                        <div className="d-flex flex-row justify-content-between">
                            <Link
                                to="/carro"
                                className="text-decoration-none text-dark"
                            >
                                <div className="d-flex flex-row">
                                    <div className="position-relative">
                                        <div className="border shadow-sm py-2 px-2 position-relative">
                                            <ShoppingCartIcon fontSize="small"></ShoppingCartIcon>
                                        </div>
                                        <div
                                            className="badge badge-danger position-absolute"
                                            style={{
                                                top: "-4px",
                                                right: "-8px",
                                                backgroundColor: "#ff6400",
                                            }}
                                        >
                                            {DatosCarro.cantidad_productos}
                                        </div>
                                    </div>
                                    <div>
                                        <Typography
                                            className="font-weight-bold ml-3"
                                            variant="body2"
                                        >
                                            CARRO
                                        </Typography>
                                        <Typography className="ml-3">
                                            ${" "}
                                            {number_format(
                                                DatosCarro.total,
                                                0,
                                                ",",
                                                "."
                                            )}
                                        </Typography>
                                    </div>
                                </div>
                            </Link>

                            <div className="d-flex flex-row position-relative">
                                <div className="position-relative">
                                    <div className="border shadow-sm py-2 px-2 position-relative">
                                        <PersonIcon fontSize="small"></PersonIcon>
                                    </div>
                                </div>
                                {IsLoged ? (
                                    <>
                                        <div
                                            className="d-flex flex-row align-items-center ml-3 h-100"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setMenuUsuarioDeploy(true);
                                            }}
                                        >
                                            <div className="btnMenu">
                                                {DatosUsuarioCliente !==
                                                null ? (
                                                    <div className="d-flex flex-row">
                                                        <Typography>
                                                            {
                                                                DatosUsuarioCliente.nombre
                                                            }
                                                        </Typography>
                                                        <ArrowDropDownIcon
                                                            fontSize="small"
                                                            style={{
                                                                color:
                                                                    EstilosGlobales.colorPrimario,
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        {MenuUsuarioDeploy ? (
                                            <MenuUsuario MenuUsuarioDeploy={{setMenuUsuarioDeploy,MenuUsuarioDeploy}} className="position-absolute" />
                                        ) : (
                                            ""
                                        )}
                                    </>
                                ) : (
                                    <div className="d-flex flex-row ml-2 align-items-center">
                                        <Link
                                            className="text-decoration-none text-dark"
                                            to="/login"
                                        >
                                            <Typography
                                                className="ml-1"
                                                variant="body2"
                                            >
                                                LOGIN
                                            </Typography>
                                        </Link>
                                        <span className="ml-1">/</span>
                                        <Link
                                            className="text-decoration-none text-dark"
                                            to="/registrar"
                                        >
                                            <Typography
                                                className="ml-1"
                                                variant="body2"
                                            >
                                                REGISTRAR
                                            </Typography>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MenuDown></MenuDown>
        </div>
    );
}

function LineaVertical() {
    const EstilosLinea = {
        height: "100%",
        width: "1px",
    };
    return <div className={"mr-1 border-right"} style={EstilosLinea}></div>;
}
