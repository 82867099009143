import CardDn2 from "../CardDn2.jsx";
import { Link } from "react-router-dom";

export default function SubPostBlog(props) {
    return (
        <Link to={"/blogpost/"+props.DatosPost.ID} className={props.className+" text-decoration-none"}>
            <CardDn2
                titulo={props.DatosPost.titulo}
                subtitulo={props.DatosPost.subtitulo}
                imagen={
                    process.env.PUBLIC_URL +
                    "/assets/imagenes/blog/posts/" +
                    props.DatosPost.imagen_portada
                }
                botonTexto="Ver más"
            ></CardDn2>
        </Link>
    );
}
