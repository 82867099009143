
import { Card, Typography} from "@material-ui/core";
import Ruta from "../Ruta";
import Titulo from "../Titulo";
import {Link} from "react-router-dom"
import {useParams} from 'react-router-dom'
import {useEffect,useState} from "react";
import axios from 'axios'
import {CircularProgress} from '@material-ui/core'

export default function ContenidoLogin() {
    let {id_activacion}=useParams();

    const Rutas = [{ nombre: "Inicio", ruta: "/" }, { nombre: "Activación de Cuenta" }];

    const [Estado,setEstado]=useState("inicio");
    const [Contenido,setContenido]=useState({
        title:<Typography variant="h5">Cargando</Typography>,
        body:<CircularProgress className="mt-2"/>
    })

    useEffect(()=>{
        if(Estado==="inicio"){
            cargarActivacion();
        }
    })

    const cargarActivacion=()=>{
        setEstado("cargando");
        var datos = new FormData();
        datos.append("id_activacion",id_activacion);
        axios({
            url: process.env.PUBLIC_URL+"/assets/api/cuentas/cuenta_activar.php",
            method:"post",
            data:datos
        }).then((resp)=>{
            var res = resp.data;
            var title_print = "";
            var body_print= "";
            //console.log(res);
            if(res[0]==="realizado"){
                title_print=<Typography className="font-weight-bold text-success" variant="h5">Cuenta Activada</Typography>;
                body_print=<Typography className="" variant="body1">La cuenta de usuario fue <b className="text-success">activada satisfactoriamente</b>. Para ingresar a la cuenta: <Link to="/login">Login</Link></Typography>;
                setContenido({
                    title:title_print,
                    body:body_print
                })
            }else if(res[0]==="error_activada"){
                title_print=<Typography className="font-weight-bold" variant="h5">Cuenta ya activada</Typography>;
                body_print=<Typography className="" variant="body1">La cuenta ya se encuentra <b>activada</b>. Ingrese a su cuenta: <Link to="/login">Login</Link></Typography>;
                setContenido({
                    title:title_print,
                    body:body_print
                })
            }else if(res[0]==="error_noexiste"){
                title_print=<Typography className="font-weight-bold" variant="h5">ID de activación incorrecto</Typography>;
                body_print=<Typography className="font-weight-bold text-danger" variant="body1">ID de activación se encuentra incorrecto.</Typography>;
                setContenido({
                    title:title_print,
                    body:body_print
                })
            }
        })
    }

    return (
        <div>
            <Ruta ruta={Rutas}></Ruta>
            <div className="bg-light">
                <div
                    className="container py-2"
                    style={{ minHeight: window.innerHeight * 0.6 }}
                >
                    <Titulo titulo="Cuenta"></Titulo>
                    <div className="row py-4 justify-content-center">
                       <div className="col-md-8 col-sm-10 col-12">
                           <Card className="py-4 px-2 text-center" style={{minHeight:"250px"}}>
                               <Titulo className="px-5 mb-4">Activación de Cuenta</Titulo>
                                {Contenido.title}
                                {Contenido.body}
                            </Card> 
                               
                       </div> 
                    </div>
                </div>
            </div>
        </div>
    );
}
