
import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoMiCuenta from '../components/cuentas/ContenidoMiCuenta.jsx'
import MetaData from '../context/MetaData'

export default function MiCuenta(){
    return(
        <div>         
            <MetaData titulo="Mi Cuenta"/>
            <Header>            
            </Header>
            <ContenidoMiCuenta></ContenidoMiCuenta>
            <Footer></Footer>
        </div>
    );
}
