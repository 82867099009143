import { Button, TextField, Typography } from "@material-ui/core";
import number_format from '../../context/numberFormat'

import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Especificaciones from './Especificaciones'
import InboxIcon from '@material-ui/icons/Inbox';
import EstilosGlobales from '../../context/EstilosGlobales'


export default function DatosProductoPrincipal(props) {

    return (
        <div className={props.className}>
            <div className="bg-white shadow-sm px-3 pb-4 pt-2">
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <Typography variant="body2">
                        <b style={{ color: "#ff6400" }}>SKU:</b> {props.DatosProducto.SKU}
                    </Typography>
                    {props.DatosProducto.imagen_superfamilia ?
                        <div className="d-flex flex-column align-items-center">
                            <img src={process.env.PUBLIC_URL + "/assets/imagenes/superfamilias/" + props.DatosProducto.imagen_superfamilia} alt="Sup" style={{ maxWidth: "60px" }}></img>
                            <Typography variant="caption" style={{ color: "#ff6400" }}>{props.DatosProducto.superfamilia}</Typography>
                        </div>
                        :
                        ""
                    }
                </div>
                <div className="mt-0">
                    <Typography className="text-secondary" variant="caption">
                        {props.DatosProducto.marca}
                    </Typography>
                </div>
                <Typography className="mt-0" variant="h5">
                    {props.DatosProducto.nombre}
                </Typography>
                <div className="mt-1">
                </div>
                {
                    parseInt(props.DatosProducto.precio) < parseInt(props.DatosProducto.precio_retail)?
                    <div className="d-flex flex-column">
                        <Typography variant="body2" className="font-weight-bold text-secondary">
                            Precio Normal
                        </Typography>
                        <Typography variant="body1" className="font-weight-bold text-secondary">
                            $ {number_format(props.DatosProducto.precio_retail, 0, ",", ".")} 
                        </Typography>
                    </div>:""
                }
                {props.DatosProducto.oferta>0?
                <div className="d-inline-block px-3 py-1 rounded shadow-sm" style={{backgroundColor:"#ff6400"}}>
                    <Typography className="w-100 text-white font-weight-bold">
                        OFERTA
                        <span className="ml-2">{number_format((1-(props.DatosProducto.precio_oferta/props.DatosProducto.precio))*100,0,",",".")}%
                        </span>
                    </Typography>
                </div>:""
                }
                
                <div className="mt-2">
                    <Typography className="font-weight-bold" style={{color:EstilosGlobales.colorPrimario}}>
                        Precio Web
                    </Typography>
                </div>
                {
                    props.DatosProducto.oferta>0?
                    
                    <div className="d-flex flex-row align-items-end">
                        <Typography className="mt-0 font-weight-bold" variant="h5" style={{ color: "#ff6400" }}>
                            $ {number_format(props.DatosProducto.precio_oferta, 0, ",", ".")}
                        </Typography>
                        <Typography className="mb-1 ml-2 font-weight-bold text-secondary" variant="body1" style={{ textDecoration: "line-through" }}>
                            $ {number_format(props.DatosProducto.precio, 0, ",", ".")}
                        </Typography>
                    </div>
                    :
                    <Typography className="mt-0 font-weight-bold" variant="h5" style={{ color: "#ff6400" }}>
                        $ {number_format(props.DatosProducto.precio, 0, ",", ".")}
                    </Typography>

                }


                <div className="mt-3 d-flex flex-row align-items-center">
                    <DashboardIcon fontSize="small" className="text-dark"></DashboardIcon>
                    <Typography variant="body2">
                        <b className="text-dark ml-1">
                            Stock:
                            {
                                props.DatosProducto.stock_total > 0 ?
                                    <span className="ml-2 text-success">Disponible</span>
                                    :
                                    <span className="ml-2 text-danger">No Disponible</span>

                            }
                        </b>
                    </Typography>
                </div>
                <div className="mt-1 d-flex flex-row align-items-center">
                    <InboxIcon fontSize="small" className="text-dark"></InboxIcon>
                    <Typography variant="body2">
                        <b className="text-dark ml-1">
                            Retiro en tienda:
                            {
                                props.DatosProducto.stock_total > 0 && props.DatosProducto.retirable > 0 ?
                                    <span className="ml-2 text-success">Disponible</span>
                                    :
                                    <span className="ml-2 text-danger">No Disponible</span>

                            }
                        </b>
                    </Typography>
                </div>
                <div className="mt-1 d-flex flex-row align-items-center">
                    <LocalShippingIcon fontSize="small" className="text-dark"></LocalShippingIcon>
                    <Typography variant="body2">
                        <b className="text-dark ml-1">
                            Despacho:
                            {
                                props.DatosProducto.stock_total > 0 && props.DatosProducto.despachable > 0 && props.DatosProducto.var_peso>0 ?
                                    <span className="ml-2 text-success">Disponible</span>
                                    :
                                    <span className="ml-2 text-danger">No Disponible</span>

                            }
                        </b>
                    </Typography>
                </div>
                <div className="mt-3">
                    <TextField
                        id="filled-number"
                        label="Cantidad"
                        type="number"
                        variant="outlined"
                        style={{width:"6em"}}
                        onChange={(e)=>{
                            if(e.target.value<0){
                                props.CantidadPadre.setCantidad(1);
                            }else{
                                props.CantidadPadre.setCantidad(e.target.value);
                            }
                        }}

                        value={props.CantidadPadre.Cantidad}
                        
                    />
                    <Button onClick={props.AgregarCarroPadre.AgregarCarro} className="ml-2 text-white mt-2" style={{backgroundColor:"#ff6400"}} size="large">
                        <AddShoppingCartIcon></AddShoppingCartIcon>
                        <span className="ml-2">Agregar</span>
                    </Button>
                </div>
                                                            
            </div>
            <Especificaciones className="mt-2 bg-white shadow-sm p-3" DatosProducto={props.DatosProducto}></Especificaciones>                               

        </div>
    );
}
