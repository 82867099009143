import Header from "../components/general/Header";
import Footer from "../components/general/Footer";
import { createMuiTheme, ThemeProvider, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import EstilosGlobales from "../context/EstilosGlobales";
import { Redirect } from "react-router-dom";
import axios from 'axios'
import {useEffect,useState} from 'react'

const theme = createMuiTheme();

export default function NoEncontrado() {
    return (
        <>
            <Helmet>
                <title>Pagina no encontrada - DIMASA</title>
            </Helmet>
            <Header></Header>
            <CuerpoError />
            <Footer></Footer>
        </>
    );
}

function CuerpoError() {
    let { tipo_error } = useParams();

    const TipoErrores = [
        "er2",
        "er3",
        "er4a",
        "er4",
        "er4",
        "er1",
        "er_stock",
        "er5",
        "er6",
        "er7",
        "er8",
    ];

    if (!TipoErrores.includes(tipo_error)) {
        return <Redirect to="/404" />;
    }

    return (
        <section
            className="bg-light"
            style={{ height: window.innerHeight * 0.6 }}
        >
            <div className="container py-4">
                <div className="border bg-white rounded shadow-sm p-3 p-lg-5">
                    <div className="row">
                        <RedaccionError tipo_error={tipo_error}/>
                    </div>
                </div>
            </div>
        </section>
    );
}

function RedaccionError(props) {
    let {tipo_error} = props;
    const [ProductosSinStock,setProductosSinStock] = useState([
    ]);

    useEffect(()=>{
        const revisarProductosCarro=()=>{
            axios({
                url: process.env.PUBLIC_URL+"/assets/api/carrodn/carrodn_ver.php"
            }).then((resp)=>{
                var res = resp.data;
                if(res['productos_sin_stock'] && res['productos_sin_stock'].length>0){
                    setProductosSinStock(res['productos_sin_stock']);
                }
            }) 
        }

        revisarProductosCarro();
    },[])

    if(tipo_error==="er_stock"){
        return(
            <div className="col">
                <Typography
                    variant="h4"
                    style={{ color: EstilosGlobales.colorPrimario }}
                >
                    Problemas al procesar la Compra
                </Typography>
                <ThemeProvider theme={theme}>
                    <Typography variant="body1" className="text-dark my-3">
                        Mientras se procesaba la compra, alguno de los productos que estaba en el carro <b>redujeron su stock</b> por debajo de la cantidad deseada de compra:
                    </Typography>
                </ThemeProvider>
                <div className="">
                    {
                        ProductosSinStock.map((producto,index)=>{
                            return(
                                <Link key={index} to={"/producto/"+producto.SKU}>
                                    <Typography variant="body2">
                                        <b>
                                            SKU: {producto.SKU}
                                            </b> - <span className="font-weight-bold text-dark">{producto.nombre}</span>
                                    </Typography>
                                </Link>
                            )
                        })
                    }
                </div>
                <Typography variant="body1" className="text-dark my-3">
                    <span className="">
                        <b>Corrobora el Stock disponible</b> de los productos y vuelve a intentar realizar tu compra: 
                        productos: <Link className="font-weight-bold" to="/carro" style={{color:EstilosGlobales.colorPrimario}}>COMPRAR.</Link>{" "}
                    </span>
                    <br />
                </Typography>
            </div>
        );
    }else{
        return (
            <div className="col">
                <Typography
                    variant="h4"
                    style={{ color: EstilosGlobales.colorPrimario }}
                >
                    Error en la Compra
                </Typography>
                <ThemeProvider theme={theme}>
                    <Typography variant="h6" className="text-dark my-3">
                        Al intentar realizar la compra ocurrio un error codigo:
                        <span
                            className="font-weight-bold"
                            style={{
                                color: EstilosGlobales.colorPrimario,
                            }}
                        >
                            {tipo_error}
                        </span>
                        .{" "}
                    </Typography>
                </ThemeProvider>
                <Typography variant="body1" className="text-dark my-3">
                    <span className="font-weight-bold">
                        Intenta de nuevo realizar la compra y corrobora tus
                        productos: <Link style={{color:EstilosGlobales.colorPrimario}} to="/carro">COMPRAR.</Link>{" "}
                    </span>
                    <br />
                    <i className="text-danger">
                        <b>NOTA:</b> Si el problema persiste, informanos por medio de nuestros
                        canales de contacto junto con el código del error.
                    </i>
                </Typography>
            </div>
        );
    }
}
