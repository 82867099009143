import Header from '../components/general/Header'
import Footer from '../components/general/Footer'
import ContenidoCarroVer from '../components/carro/ContenidoCarroVer.jsx'

export default function Carro(){
    return(
        <div>
            <Header></Header>
            <ContenidoCarroVer></ContenidoCarroVer>
            <Footer></Footer>

        </div>
    );
}