import { useEffect, useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios'
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Skeleton } from '@material-ui/lab';


export default function SideBar(props) {

    const [Estado, setEstado] = useState("inicio");
    const [Categorias, setCategorias] = useState([{}]);

    useEffect(() => {
        if (Estado === "inicio") {
            cargarCategorias();
        }

        
    })

    const cargarCategorias = () => {
        setEstado("cargando");
        axios({
            url: process.env.PUBLIC_URL + "/assets/api/superfamilia_familia_subfamilia_cargar.php"
        }).then((resp) => {
            var res = resp.data;
            setCategorias(res);
            setEstado("cargado");
        })
    }

    const handleClickSubfamilia=()=>{
        props.EstadoPadre.setEstado("inicio");
    }


    if (Estado === "inicio" || Estado === "cargando") {
        return (
            <div className={props.className}>
                <Skeleton variant="rect" height={630}></Skeleton>                
            </div>
        );
    } else if (Estado === "cargado") {
        return (
            <div className={props.className}>
                <div id="accordion">
                    {
                        Categorias.map((superfamilia, index) => {

                            return (
                                <div className="card" key={index}>
                                    <div className="card-header bg-white p-0" id="headingOne">
                                        <h5 className="mb-0">
                                            <Button className="w-100 d-flex justify-content-between" data-toggle="collapse" data-target={"#collapse" + index} aria-expanded="true" aria-controls="collapseOne">
                                                <Typography className="font-weight-bold text-left" style={{ color: "#ff6400" }} variant="caption">
                                                    {superfamilia.superfamilia}
                                                </Typography>
                                                <ExpandMoreIcon fontSize="small"></ExpandMoreIcon>
                                            </Button>
                                        </h5>
                                    </div>

                                    <div id={"collapse" + index} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div className="card-body py-0 px-4">
                                            <div id="subaccordion">
                                                {
                                                    superfamilia.familias.map((familia,indexj)=>{
                                                        return(
                                                        <div key={indexj}>
                                                            <div id="headingSub">
                                                                <Button className="py-1 px-0 w-100 d-flex justify-content-between" data-toggle="collapse" data-target={"#collapseSub"+index+indexj} aria-expanded="true" aria-controls="collapseOne">
                                                                    <Typography className="font-weight-bold text-left" variant="caption">
                                                                        {familia.familia}
                                                                    </Typography>
                                                                    <ExpandMoreIcon fontSize="small"></ExpandMoreIcon>
                                                                </Button>
                                                            </div>
                                                            <div id={"collapseSub"+index+indexj} className="collapse" aria-labelledby="headingSub" data-parent="#subaccordion">
                                                                <div className="d-flex flex-column">
                                                                    {
                                                                        familia.subfamilias.map((subfamilia,indexz)=>{
                                                                            return(
                                                                                <Link className="text-decoration-none" to={"/productos/"+encodeURIComponent(superfamilia.superfamilia)+"/"+encodeURIComponent(familia.familia)+"/"+encodeURIComponent(subfamilia.subfamilia)} key={indexz}>
                                                                                    <ArrowRightIcon style={{color:"#ff6400"}}></ArrowRightIcon>
                                                                                    <Typography
                                                                                     onClick={handleClickSubfamilia}
                                                                                     className="text-dark" variant="caption">
                                                                                        {subfamilia.subfamilia}
                                                                                    </Typography>
                                                                                </Link>
                                                                            );
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );

                        })
                    }
                </div>                
            </div>
        );
    }
}

